import { Epoch } from '@/core/epoch';
import { getChainConstant } from '@/domain/chain';
import { SubgraphUrl } from '@/domain/subgraph';
import useChainId from '@/hooks/useChainId';
import querySubgraph from '@/infrastructure/subgraph';
import { useQuery } from '@tanstack/react-query';

const TWO_WEEKS = 1209600;

const useEpoch = (epochId?: string) => {
  const chainId = useChainId();

  return useQuery({
    queryKey: ['epoch', epochId, chainId],
    queryFn: async () => {
      const data = await querySubgraph<{ epoch: Epoch }>({
        url: getChainConstant(SubgraphUrl, chainId),
        query: `query Epoch {
          epoch(id: "${epochId}") {
            id
            type
            startTime
            endTime
            sampleSize
            collections(
              where: {
                or: [
                  { collection_: { approved: true } },
                  { collection_: { approved:false, unapprovedAt_not: null } }
                ]
              }
            ) {
              id
              votedFor
              votedAgainst
              votesFor
              votesAgainst
              collection {
                id
                name
                symbol
                unapprovedAt
                approved
              }
            }
            newCollections {
              id
              votesFor
              votedFor
              collection {
                id
                name
                symbol
              }
            }
            votes
            sweep {
              id
              sweptAt
              status
              sweepSize
              yieldSize
              transaction
            }
          }
        }`,
      });

      // Filter out collections that we dont want to include wihtin this epoch
      if (data?.epoch?.collections) {
        data.epoch.collections = data.epoch.collections.filter((c) => {
          // dont need weth or erroneous v1 punk collection
          if (
            c.collection.name === 'WETH' ||
            c.collection.name === 'V1 Cryptopunks (Wrapped)'
          ) {
            return false;
          }

          // collections that have been unapproved and removed from the treasury before this epoch
          if (
            c.collection.approved === false &&
            Number(c.collection.unapprovedAt) - TWO_WEEKS <
              Number(data.epoch.startTime)
          ) {
            return false;
          }

          return true;
        });
      }

      return data?.epoch as Epoch;
    },
    enabled: !!epochId,
  });
};

export default useEpoch;
