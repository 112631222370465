import { useQuery } from '@tanstack/react-query';
import useCollections from './useCollections';

const useCollection = (collectionId?: string) => {
  const { data: collections } = useCollections();

  return useQuery({
    queryKey: ['collection', collectionId],
    queryFn: () => {
      return collections?.find((c) => c.id === collectionId);
    },
    enabled: !!collections && !!collectionId,
  });
};

export default useCollection;
