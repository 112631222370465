'use client';

import { Dispatch, SetStateAction, useMemo } from 'react';
import SweepTable from './TableSweep';
import useEpoch from '@/application/epochs/useEpoch';
import { formatEther } from 'viem';
import useEpochs from '@/application/epochs/useEpochs';
import useTotalSweepPowerUsed from '@/application/staking/useTotalSweepPowerUsed';
import useCurrentEpochYield from '@/application/epochs/useCurrentEpochYield';
import useMinSweepAmount from '@/application/sweeps/useMinSweep';
import { useChain } from '@/hooks/useChain';

const TableSweepContainer = ({
  epochId,
  setCollection,
  collectionId,
}: {
  epochId?: any;
  collectionId?: any;
  setCollection: Dispatch<SetStateAction<any>>;
}) => {
  const chain = useChain();
  const { data: epoch } = useEpoch(epochId);
  const { data: minSweepAmount } = useMinSweepAmount();
  const { data: totalSweepPowerUsed } = useTotalSweepPowerUsed({
    epochId,
    type: epoch?.type,
  });

  const { data: epochs } = useEpochs();
  const previousEpochId = epochs?.find(
    (e) => Number(e.id) < Number(epochId) && e.type === epoch?.type,
  )?.id;

  const { data: previousEpoch } = useEpoch(previousEpochId);

  const { data: epochYieldData } = useCurrentEpochYield();

  const [, , currentYield] = epochYieldData || [];

  const sweepSize = epoch?.sweep?.sweepSize;

  // use the minSweepAmount if it's higher than the currentYield
  const sweepAmount = useMemo(() => {
    if (sweepSize != null) {
      return BigInt(sweepSize);
    }
    if (minSweepAmount != null && currentYield != null) {
      return minSweepAmount > currentYield ? minSweepAmount : currentYield;
    }

    if (minSweepAmount != null) {
      return minSweepAmount;
    }

    // still awaiting values
    return null;
  }, [currentYield, minSweepAmount, sweepSize]);

  const onCollectionClick = (id: string) =>
    setCollection(collectionId === id ? null : id);

  // highest voted first
  const collections = useMemo(() => {
    if (!epoch) {
      return [];
    }

    return epoch.collections
      .sort(
        (a, b) =>
          Number(formatEther(BigInt(b.votesFor))) -
          Number(formatEther(BigInt(b?.votesAgainst || 0))) -
          (Number(formatEther(BigInt(a.votesFor))) -
            Number(formatEther(BigInt(a.votesAgainst || 0)))),
      )
      .filter((a) => a.collection.symbol !== 'WETH');
  }, [epoch]);

  const previousCollections = useMemo(() => {
    if (!previousEpoch) {
      return [];
    }

    return previousEpoch.collections
      .sort(
        (a, b) =>
          Number(formatEther(BigInt(b.votesFor))) -
          Number(formatEther(BigInt(b?.votesAgainst || 0))) -
          (Number(formatEther(BigInt(a.votesFor))) -
            Number(formatEther(BigInt(a.votesAgainst || 0)))),
      )
      .filter((a) => a.collection.symbol !== 'WETH');
  }, [previousEpoch]);

  const winningSweepPowerUsed = collections?.reduce((acc, cur, i) => {
    if (i < Number(epoch?.sampleSize)) {
      return acc + BigInt(cur.votesFor) - BigInt(cur?.votesAgainst || 0n);
    }

    return acc;
  }, 0n);

  const viewOnly = epoch?.sweep?.status != null;

  return (
    <SweepTable
      onCollectionClick={onCollectionClick}
      collectionId={collectionId}
      collections={collections}
      previousCollections={previousCollections}
      sampleSize={epoch?.sampleSize}
      totalSweepPowerUsed={totalSweepPowerUsed}
      winningSweepPowerUsed={winningSweepPowerUsed}
      sweepAmount={sweepAmount}
      chain={chain}
      viewOnly={viewOnly}
    />
  );
};

export default TableSweepContainer;
