import { useTranslations } from 'use-intl';
import { ReactNode } from 'react';

const VoteDisconnected = ({ connect }: { connect: ReactNode }) => {
  const t = useTranslations('Epoch.Vote.Disconnected');
  return (
    <div className="rounded-xl p-4 space-y-4 bg-gradient-to-br from-brand-blue/40 via-brand-blue/10 to-brand-blue/10">
      <h4 className="text-xs">{t('connect')}</h4>
      <p className="flex items-center uppercase">
        <span className="font-display text-center py-4 text-brand-blue flex-1">
          {t('text')}
        </span>
      </p>
      {connect}
    </div>
  );
};

export default VoteDisconnected;
