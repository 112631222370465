import { Epoch, EpochNewCollection } from '@/core/epoch';
import CollectionImage from '@/presentation/components/elements/CollectionImage';
import { absBigInt } from '@/utils/bigint';
import { Fragment } from 'react';
import { useFormatter } from 'use-intl';
import { Chain, formatEther } from 'viem';

type TableRowNewCollectionProps = EpochNewCollection & {
  chain: Chain;
  collectionId: any;
  onCollectionClick: (c: any) => void;
  type?: Epoch['type'];
  sampleSize?: Epoch['sampleSize'];
  totalSweepPowerUsed?: bigint;
  index: number;
  viewOnly: boolean;
};

const TableRowNewCollection = ({
  totalSweepPowerUsed,
  votesFor,
  votesAgainst,
  votedFor,
  id,
  collection,
  index,
  onCollectionClick,
  collectionId,
  viewOnly,
}: TableRowNewCollectionProps) => {
  const { number } = useFormatter();

  const sweepPower = BigInt(votesFor) - BigInt(votesAgainst || 0);
  const totalVotes = BigInt(votesFor) + BigInt(votesAgainst || 0);

  const percentOfVote = !!totalSweepPowerUsed
    ? Number(
        formatEther(
          (absBigInt(totalVotes) * BigInt(1e18)) / (totalSweepPowerUsed || 1n),
        ),
      )
    : 0;

  const isWinning = index === 0;

  const isRunnerUp = index > 0 && index < 3;

  const isSelected = collectionId === collection.id;

  return (
    <Fragment key={id}>
      {index === 1 ? (
        <tr key={`winners`}>
          <td colSpan={3} className="border-t-2 border-brand-blue"></td>
        </tr>
      ) : null}
      {index === 3 ? (
        <tr key={`runnersup`}>
          <td colSpan={3} className="border-t-2 border-brand-pink"></td>
        </tr>
      ) : null}
      <tr
        onClick={() => !viewOnly && onCollectionClick(collection.id)}
        className={`${!viewOnly ? 'cursor-pointer' : ''} ${
          isWinning || isRunnerUp
            ? isSelected
              ? `${isWinning ? 'bg-brand-blue/30' : 'bg-brand-pink/20'}`
              : `${isWinning ? 'bg-brand-blue/10 hover:bg-brand-blue/20' : 'bg-brand-pink/10 hover:bg-brand-pink/20'}`
            : isSelected
              ? 'bg-black'
              : 'hover:bg-gray-600 bg-gray-800'
        } `}
      >
        <td className={`px-4 py-2`}>
          <div className="flex items-center">
            {id ? (
              <CollectionImage
                collection={collection.id}
                className="h-12 w-12"
                diameter={48}
              />
            ) : (
              <span className="w-12 h-12 bg-brand-pink rounded-sm inline-flex" />
            )}
            <span className="ml-4 truncate max-w-[24ch]">
              {collection.name}
            </span>
          </div>
        </td>
        <td className="px-4 py-2 text-right">
          {number(Number(votedFor || 0))}
        </td>
        <td className="px-4 py-2 text-right">
          {number(Number(formatEther(sweepPower)), {
            maximumFractionDigits: 0,
            signDisplay: 'negative',
          })}
          <span className="ml-1 text-sm text-gray-200">
            {`(${number(percentOfVote, {
              style: 'percent',
              maximumFractionDigits: 1,
            })})`}
          </span>
        </td>
      </tr>
    </Fragment>
  );
};

export default TableRowNewCollection;
