import { Epochs } from '@/core/epoch';
import { Dispatch, SetStateAction } from 'react';
import InfoBar from './InfoBar';
import SelectEpoch from './SelectEpoch';
import { Chain } from 'viem';
import { useChain } from '@/hooks/useChain';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';

const EpochEmpty = ({
  epochs,
  setSelected,
  selected,
}: {
  epochs: Epochs;
  selected: any;
  setSelected: Dispatch<SetStateAction<any>>;
}) => {
  const chain = useChain();

  return (
    <div className="py-4 space-y-4">
      <div className="rounded-xl bg-gray-800 py-2 md:py-4 space-y-4 md:space-y-0 md:flex md:items-center">
        <section className="px-2 md:px-4">
          <SelectEpoch
            epochs={epochs}
            active={selected}
            onChange={setSelected}
            chain={chain}
          />
        </section>
        <p className="text-center flex-1 flex items-center justify-center">
          <ExclamationTriangleIcon className="w-6 h-6 mr-2" />
          {`Floor War ${selected} not found.`}
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <section className="lg:col-span-2">
          <div className="rounded-xl bg-gray-800 py-2">
            <div className="overflow-x-scroll hide-scroll">
              <table className="w-full">
                <thead className="text-left text-xs text-gray-200 whitespace-nowrap">
                  <tr>
                    <th className="px-4 py-2 font-medium">
                      {
                        <div className="bg-gray-600 w-16 h-6 rounded-lg inline-block" />
                      }
                    </th>
                    <th className="px-4 py-2 font-medium text-right">
                      {
                        <div className="bg-gray-600 w-12 h-6 rounded-lg inline-block" />
                      }
                    </th>
                    <th className="px-4 py-2 font-medium text-right">
                      {
                        <div className="bg-gray-600 w-16 h-6 rounded-lg inline-block" />
                      }
                    </th>
                  </tr>
                </thead>
                <tbody className="text-sm">
                  {[null, null, null, null, null, null].map((c, i) => (
                    <tr key={i}>
                      <td className="px-4 py-2">
                        <div className="flex items-center">
                          <span className="mr-4">
                            <div className="bg-gray-600 w-4 h-4 rounded-lg" />
                          </span>
                          <span className="w-12 h-12 bg-gray-600 rounded-sm inline-flex" />
                          <span className="ml-4 truncate">
                            <div className="bg-gray-600 w-16 h-4 rounded-lg mb-2" />
                            <div className="bg-gray-600 w-32 h-4 rounded-lg" />
                          </span>
                        </div>
                      </td>
                      <td className="px-4 py-2 text-right">
                        <div className="bg-gray-600 w-20 h-6 rounded-lg inline-block" />
                      </td>

                      <td className="px-4 py-2 text-right">
                        <div className="bg-gray-600 w-24 h-6 rounded-lg inline-block" />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </section>
        <aside className="lg:col-span-1 space-y-4">
          <div className="rounded-xl bg-gradient-to-br from-gray-600 via-brand-blue/10 to-brand-blue/10 py-4 flex flex-col">
            <header className="flex items-center px-4 mt-4">
              <div className="bg-gray-800 w-16 h-6 rounded-lg" />
            </header>
            <div className="flex-1 bg-gray-800 rounded-lg mx-4 my-4" />
            <div className="mt-auto rounded-lg bg-gray-800 h-16 mx-4 my-4" />
          </div>
          <div className="bg-gray-800 rounded-xl">
            <header className="border-b border-gray-400 p-4 flex space-x-4">
              <div className="bg-gray-600 w-16 h-6 rounded-lg" />
            </header>
            <div className="space-y-2 p-4">
              <div className="bg-gray-600 h-6 rounded-lg" />
              <div className="bg-gray-600 h-6 rounded-lg" />
              <div className="bg-gray-600 h-6 rounded-lg" />
            </div>
          </div>
        </aside>
      </div>
    </div>
  );
};

export default EpochEmpty;
