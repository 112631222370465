import { getChainConstant } from '@/domain/chain';
import { SubgraphUrl } from '@/domain/subgraph';
import useChainId from '@/hooks/useChainId';
import querySubgraph from '@/infrastructure/subgraph';
import { useQuery } from '@tanstack/react-query';
import { Address } from 'viem';
import { useAccount } from 'wagmi';

const useVoteLock = ({ userAddress }: { userAddress?: Address } = {}) => {
  const { address } = useAccount();
  const chainId = useChainId();

  const addr = userAddress || address;

  return useQuery({
    queryKey: ['voteLock', addr, chainId],
    queryFn: async () => {
      const data = (await querySubgraph({
        url: getChainConstant(SubgraphUrl, chainId),
        query: `{
          voteLock(id: "${addr?.toLowerCase()}") {
            lockEnd
            lockStart
            amount
          }
        }`,
      })) as {
        voteLock: { lockEnd: string; lockStart: string; amount: string };
      };

      return data.voteLock;
    },
    enabled: !!addr,
  });
};

export default useVoteLock;
