const EpochStatus = ({ status }: { status: 'LIVE' | 'PENDING' | 'SWEPT' }) => {
  return status === 'LIVE' ? (
    <div className="inline-flex items-center bg-gradient-to-b from-brand-red/0 to-brand-red/20 border border-brand-red rounded-full py-2 px-6 text-white text-xs">
      <span className="h-2 w-2 bg-brand-red rounded-full mr-4 animate-pulse" />
      {'Live'}
    </div>
  ) : status === 'SWEPT' ? (
    <div className="inline-flex items-center bg-gradient-to-b from-brand-blue/0 to-brand-blue/20 border border-brand-blue rounded-full py-2 px-6 text-white text-xs">
      <span className="h-2 w-2 bg-brand-blue rounded-full mr-4" />
      {'Swept'}
    </div>
  ) : (
    <div className="inline-flex items-center bg-gradient-to-b from-brand-amber/0 to-brand-amber/20 border border-brand-amber rounded-full py-2 px-6 text-white text-xs">
      <span className="h-2 w-2 bg-brand-amber rounded-full mr-4" />
      {'Pending'}
    </div>
  );
};

export default EpochStatus;
