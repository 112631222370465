import { CollectionsResponse } from '@/core/collection';
import { getChainConstant } from '@/domain/chain';
import { SubgraphUrl } from '@/domain/subgraph';
import { useChain } from '@/hooks/useChain';
import querySubgraph from '@/infrastructure/subgraph';
import { useQuery } from '@tanstack/react-query';

const useCollections = () => {
  const chain = useChain();
  return useQuery({
    queryKey: ['collections', chain.id],
    queryFn: async () => {
      const data = await querySubgraph<CollectionsResponse>({
        url: getChainConstant(SubgraphUrl, chain.id),
        query: `query Collections {
          collections {
            id
            name
            symbol
            approved
            approvedAt
            strategies {
              id
              name
              active
            }
          }
        }
      `,
      });

      return data.collections;
    },
  });
};

export default useCollections;
