import { Addresses } from '@/domain/addresses';
import { getChainConstant } from '@/domain/chain';
import useChainId from '@/hooks/useChainId';
import SweepWarsAbi from '@/infrastructure/abis/SweepWarsAbi';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Address } from 'viem';
import { useWaitForTransactionReceipt, useWriteContract } from 'wagmi';

const useRevokeVotes = () => {
  const client = useQueryClient();
  const chainId = useChainId();
  const {
    writeContract,
    data: hash,
    error,
    isPending,
    reset,
  } = useWriteContract();

  const {
    data,
    isLoading: isConfirming,
    isSuccess: isConfirmed,
  } = useWaitForTransactionReceipt({
    hash,
    query: {
      // prevent previous tx state from being used
      placeholderData: undefined,
    },
  });

  useEffect(() => {
    if (isConfirmed) {
      setTimeout(() => {
        client.refetchQueries({
          queryKey: ['userActiveVotes'],
          exact: false,
        });
      }, 1500);
      setTimeout(() => {
        client.refetchQueries({ queryKey: ['epochs'], exact: false });
        client.refetchQueries({ queryKey: ['epoch'], exact: false });
        reset();
      }, 3000);
    }
  }, [isConfirmed, client, reset]);

  const revokeVotes = (votes: Address[]) =>
    writeContract({
      args: [votes],
      abi: SweepWarsAbi,
      address: getChainConstant(Addresses, chainId, 'SweepWars'),
      functionName: 'revokeVotes',
    });

  return {
    revokeVotes,
    receipt: data,
    error,
    isPending,
    isConfirming,
    isConfirmed,
  };
};

export default useRevokeVotes;
