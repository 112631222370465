import { Activity, Epoch } from '@/core/epoch';
import { getChainConstant } from '@/domain/chain';
import { SubgraphUrl } from '@/domain/subgraph';
import useChainId from '@/hooks/useChainId';
import querySubgraph from '@/infrastructure/subgraph';
import { useQuery } from '@tanstack/react-query';
import { Address } from 'viem';

const useActivity = () => {
  const chainId = useChainId();
  return useQuery({
    queryKey: ['activity', chainId],
    queryFn: async () => {
      const data = await querySubgraph<{
        votes: {
          id: string;
          type: Epoch['type'];
          collection: {
            id: Address;
          };
          epoch: {
            id: string;
          };
          createdAt: string;
          updatedAt: string | null;
          votesCast: string;
          user: {
            id: Address;
          };
        }[];
        voteLocks: {
          id: string;
          user: {
            id: Address;
          };
          amount: string;
          active: boolean;
          createdAt: string;
          updatedAt: string | null;
        }[];
      }>({
        url: getChainConstant(SubgraphUrl, chainId),
        query: `query Activity {
          votes(
            first: 100,
            where:{
              revoked_not: true
            }
            orderBy: epoch__id
            orderDirection: desc
          ) {
            id
            type
            collection {
              id
            }
            epoch {
              id
            }
            createdAt
            votesCast
            user {
              id
            }
          }
          voteLocks(orderBy: updatedAt orderDirection: desc first: 100) {
            id
            user {
              id
            }
            amount
            active
            createdAt
            updatedAt
          }
        }`,
      });

      // Merge the two arrays
      const mergedData = [
        ...data.votes.map((vote) => ({
          id: vote.id,
          user: vote.user.id,
          action: vote.type,
          amount: vote.votesCast,
          timestamp: Number(vote.updatedAt || vote.createdAt),
          collection: vote.collection,
          epoch: vote.epoch.id,
        })),
        ...data.voteLocks.map((voteLock) => ({
          id: voteLock.id,
          user: voteLock.user.id,
          amount: voteLock.amount,
          action: voteLock.active ? 'LOCK' : 'UNLOCK',
          timestamp: Number(
            voteLock.active
              ? voteLock.updatedAt || voteLock.createdAt
              : voteLock.updatedAt,
          ),
        })),
      ] as Activity[];

      // Sort the merged array based on date
      // and just grab most recent 100
      return mergedData
        .sort(
          (a, b) =>
            new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(),
        )
        .slice(0, 99);
    },
  });
};

export default useActivity;
