import { NumberFormatOptions, useFormatter } from 'use-intl';
import { Chain } from 'viem';

const Price = ({
  value,
  currency,
  options,
  chain,
  symbolClassName,
}: {
  chain: Chain;
  value: number;
  currency: 'ETH' | 'USD';
  options: NumberFormatOptions;
  symbolClassName?: string;
}) => {
  const { number } = useFormatter();

  if (currency === 'ETH') {
    return (
      <>
        {`${number(value, options)}`}{' '}
        <span
          className={symbolClassName}
        >{`${chain.nativeCurrency.symbol}`}</span>
      </>
    );
  }

  return number(value, {
    maximumFractionDigits: 0,
    ...options,
    style: 'currency',
    currency: 'USD',
  });
};

export default Price;
