import { Activity } from '@/core/epoch';
import { useChain } from '@/hooks/useChain';
import CollectionImage from '@/presentation/components/elements/CollectionImage';
import User from '@/presentation/components/elements/User';
import { absBigInt } from '@/utils/bigint';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  LockClosedIcon,
  LockOpenIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { useMemo, useState } from 'react';
import { useFormatter, useTranslations } from 'use-intl';
import { formatEther } from 'viem';

const PAGE_SIZE = 10;

const Activity = ({ activity = [] }: { activity?: Activity[] }) => {
  const { blockExplorers } = useChain();
  const t = useTranslations('Epoch.Activity');
  const [offset, setOffset] = useState(0);
  const { number, relativeTime } = useFormatter();

  const now = useMemo(() => Date.now(), []);

  const visibleActivity = activity.slice(offset, offset + PAGE_SIZE);

  const totalPages = Math.ceil(activity.length / PAGE_SIZE);
  const currentPage = Math.floor(offset / PAGE_SIZE) + 1;

  const handleNext = () => {
    setOffset((prevOffset) => {
      const newOffset = Math.min(
        prevOffset + PAGE_SIZE,
        (totalPages - 1) * PAGE_SIZE,
      );
      return newOffset;
    });
  };

  const handlePrevious = () => {
    setOffset((prevOffset) => {
      const newOffset = Math.max(prevOffset - PAGE_SIZE, 0);
      return newOffset;
    });
  };

  return (
    <div className="p-4 overflow-x-scroll hide-scroll">
      <table className="w-full">
        <thead className="text-gray-200 text-left text-xxs">
          <tr>
            <th className="py-1 pr-2 font-medium">{t('table.user')}</th>
            <th className="py-1 px-2 font-medium text-left">
              {t('table.action')}
            </th>
            <th className="py-1 px-2 font-medium text-right">
              {t('table.sp')}
            </th>
            <th className="py-1 pl-2 font-medium text-right">
              {t('table.war')}
            </th>
          </tr>
        </thead>
        <tbody className="text-xs">
          {visibleActivity?.map((a) => {
            return (
              <tr key={a.id}>
                <td className="py-1 pr-2">
                  <a
                    href={`${blockExplorers?.default.url}/address/${a.user}`}
                    target="_blank"
                    rel="nofollow noreferrer"
                    className="hover:underline truncate"
                  >
                    <User address={a.user} />
                  </a>
                </td>
                <td className="py-1 px-2">
                  {a.action === 'LOCK' && (
                    <span className="flex items-center">
                      <LockClosedIcon className="w-4 h-4 mr-1.5" />
                      {'Lock'}
                    </span>
                  )}
                  {a.action === 'UNLOCK' && (
                    <span className="flex items-center">
                      <LockOpenIcon className="w-4 h-4 mr-1.5" />
                      {'Unlock'}
                    </span>
                  )}
                  {(a.action === 'NEW_COLLECTION_WAR' ||
                    a.action === 'SWEEP_WAR') && (
                    <span className={'flex items-center'}>
                      <CollectionImage
                        collection={a.collection?.id}
                        className="w-4 h-4 mr-1.5"
                      />
                      <span>{'Vote'}</span>
                      {a.action === 'SWEEP_WAR' && (
                        <span
                          className={`${
                            BigInt(a.amount) < 0n
                              ? 'bg-brand-red'
                              : 'bg-brand-green'
                          } rounded-full h-2 w-2 ml-2`}
                        />
                      )}
                      {a.action === 'NEW_COLLECTION_WAR' && (
                        <PlusIcon className="w-4 h-4 ml-1" />
                      )}
                    </span>
                  )}
                </td>
                <td className="text-right px-2">
                  {number(Number(formatEther(absBigInt(BigInt(a.amount)))), {
                    maximumFractionDigits: 2,
                    compactDisplay: 'short',
                    notation: 'compact',
                  })}
                </td>
                <td className="py-1 pl-2 text-right">
                  <span className="truncate">
                    {relativeTime(a.timestamp * 1000, { now })}
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {activity.length > PAGE_SIZE && (
        <div className="flex items-center mt-4">
          <button
            onClick={handlePrevious}
            type="button"
            disabled={offset === 0}
            className="disabled:opacity-40"
          >
            <ChevronLeftIcon className="text-white h-4 w-4" />
          </button>
          <div className="flex-1 text-xxs text-center text-gray-200">
            {`Page ${currentPage} of ${totalPages}`}
          </div>
          <button
            onClick={handleNext}
            type="button"
            disabled={offset + PAGE_SIZE >= activity.length}
            className="disabled:opacity-40"
          >
            <ChevronRightIcon className="text-white h-4 w-4" />
          </button>
        </div>
      )}
    </div>
  );
};

export default Activity;
