import { EpochNewCollection } from '@/core/epoch';
import { useTranslations } from 'use-intl';
import { Chain } from 'viem';
import CollectionTableNewRow from './TableNewCollectionRow';

const TableNewCollection = ({
  collections,
  collectionId,
  onCollectionClick,
  totalSweepPowerUsed,
  chain,
  viewOnly,
}: {
  chain: Chain;
  collectionId: any;
  collections?: EpochNewCollection[];
  onCollectionClick: (c: any) => void;
  totalSweepPowerUsed?: bigint;
  viewOnly: boolean;
}) => {
  const t = useTranslations('Epoch.Table');

  return (
    <div className="space-y-4">
      <div className="rounded-xl bg-gray-800 py-2">
        <div className="overflow-x-scroll hide-scroll">
          <table className="w-full">
            <thead className="text-left text-xs text-gray-200 whitespace-nowrap">
              <tr>
                <th className="px-4 py-2 font-medium">{t('collection')}</th>
                <th className="px-4 py-2 font-medium text-right">
                  {t(`votes.for`)}
                </th>
                <th className="px-4 py-2 font-medium text-right">
                  {t('sweepPower')}
                </th>
              </tr>
            </thead>
            <tbody className="text-sm">
              {collections?.map((c, i) => {
                return (
                  <CollectionTableNewRow
                    {...c}
                    onCollectionClick={onCollectionClick}
                    chain={chain}
                    collectionId={collectionId}
                    totalSweepPowerUsed={totalSweepPowerUsed}
                    index={i}
                    key={c.id}
                    viewOnly={viewOnly}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="rounded-xl bg-gray-800 p-4 text-xs">
        <h4 className="text-sm text-white/75 mb-2">{'New Collection War'}</h4>
        <dl className="space-y-2 text-white/75">
          <div className="flex items-center">
            <dt className="mr-2">
              <div className="w-4 h-4 rounded-sm bg-brand-blue/50" />
            </dt>
            <dd>
              {
                'Winning collection will be added to the Treasury and swept, and included in all future Sweep Wars.'
              }
            </dd>
          </div>
          <div className="flex items-center">
            <dt className="mr-2">
              <div className="w-4 h-4 rounded-sm bg-brand-pink/50" />
            </dt>
            <dd>
              {
                'Runner up collections will be included in all future Sweep Wars.'
              }
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

export default TableNewCollection;
