const EpochLoading = () => (
  <div className="py-4 space-y-4 animate-pulse">
    <div className="rounded-xl bg-gray-800 py-2 md:py-4 space-y-4 md:space-y-0 md:flex">
      <section className="px-2 md:px-4 w-1/2 flex-none flex items-center">
        <div
          className={`relative flex bg-gray-600 h-20 p-2 md:p-4 rounded-lg w-1/2`}
        />
        <div className="bg-gray-600 h-10 flex-1 ml-8 rounded-md" />
      </section>
      <section className="px-2 md:px-4 md:flex flex-1 md:pl-0 items-center space-x-4">
        <div className="bg-gray-600 h-1 flex-1 ml-8 rounded-md" />
        <div className="w-24 h-10 rounded-full bg-gray-600" />
      </section>
    </div>
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      <section className="lg:col-span-2">
        <div className="rounded-xl bg-gray-800 py-2">
          <div className="overflow-x-scroll hide-scroll">
            <table className="w-full">
              <thead className="text-left text-xs text-gray-200 whitespace-nowrap">
                <tr>
                  <th className="px-4 py-2 font-medium">
                    {
                      <div className="bg-gray-600 w-16 h-6 rounded-lg inline-block" />
                    }
                  </th>
                  <th className="px-4 py-2 font-medium text-right">
                    {
                      <div className="bg-gray-600 w-12 h-6 rounded-lg inline-block" />
                    }
                  </th>
                  <th className="px-4 py-2 font-medium text-right">
                    {
                      <div className="bg-gray-600 w-16 h-6 rounded-lg inline-block" />
                    }
                  </th>
                </tr>
              </thead>
              <tbody className="text-sm">
                {[null, null, null, null, null, null].map((c, i) => (
                  <tr key={i}>
                    <td className="px-4 py-2">
                      <div className="flex items-center">
                        <span className="mr-4">
                          <div className="bg-gray-600 w-4 h-4 rounded-lg" />
                        </span>
                        <span className="w-12 h-12 bg-gray-600 rounded-sm inline-flex" />
                        <span className="ml-4 truncate">
                          <div className="bg-gray-600 w-16 h-4 rounded-lg mb-2" />
                          <div className="bg-gray-600 w-32 h-4 rounded-lg" />
                        </span>
                      </div>
                    </td>
                    <td className="px-4 py-2 text-right">
                      <div className="bg-gray-600 w-20 h-6 rounded-lg inline-block" />
                    </td>

                    <td className="px-4 py-2 text-right">
                      <div className="bg-gray-600 w-24 h-6 rounded-lg inline-block" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
      <aside className="lg:col-span-1 space-y-4">
        <div className="rounded-xl bg-gradient-to-br from-gray-600 via-brand-blue/10 to-brand-blue/10 py-4 flex flex-col">
          <header className="flex items-center px-4 mt-4">
            <div className="bg-gray-800 w-16 h-6 rounded-lg" />
          </header>
          <div className="flex-1 bg-gray-800 rounded-lg mx-4 my-4" />
          <div className="mt-auto rounded-lg bg-gray-800 h-16 mx-4 my-4" />
        </div>
        <div className="bg-gray-800 rounded-xl">
          <header className="border-b border-gray-400 p-4 flex space-x-4">
            <div className="bg-gray-600 w-16 h-6 rounded-lg" />
          </header>
          <div className="space-y-2 p-4">
            <div className="bg-gray-600 h-6 rounded-lg" />
            <div className="bg-gray-600 h-6 rounded-lg" />
            <div className="bg-gray-600 h-6 rounded-lg" />
          </div>
        </div>
      </aside>
    </div>
  </div>
);

export default EpochLoading;
