'use client';

import { Dispatch, SetStateAction, useMemo } from 'react';
import NewCollectionTable from './TableNewCollection';
import useEpoch from '@/application/epochs/useEpoch';
import { formatEther } from 'viem';
import useTotalSweepPowerUsed from '@/application/staking/useTotalSweepPowerUsed';
import { useChain } from '@/hooks/useChain';

const TableNewCollectionContainer = ({
  epochId,
  setCollection,
  collectionId,
}: {
  epochId?: any;
  collectionId?: any;
  setCollection: Dispatch<SetStateAction<any>>;
}) => {
  const chain = useChain();
  const { data: epoch } = useEpoch(epochId);
  const { data: totalSweepPowerUsed } = useTotalSweepPowerUsed({
    epochId,
    type: epoch?.type,
  });

  const onCollectionClick = (id: string) =>
    setCollection(collectionId === id ? null : id);

  // highest voted first
  const collections = useMemo(() => {
    if (!epoch) {
      return [];
    }

    return epoch.newCollections
      .sort(
        (a, b) =>
          Number(formatEther(BigInt(b.votesFor))) -
          Number(formatEther(BigInt(a.votesFor))),
      )
      .filter((a) => a.collection.symbol !== 'WETH');
  }, [epoch]);

  const viewOnly = epoch?.sweep?.status != null;

  return (
    <NewCollectionTable
      onCollectionClick={onCollectionClick}
      collectionId={collectionId}
      collections={collections}
      totalSweepPowerUsed={totalSweepPowerUsed}
      chain={chain}
      viewOnly={viewOnly}
    />
  );
};

export default TableNewCollectionContainer;
