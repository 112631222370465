import { Epoch } from '@/core/epoch';
import { Vote } from '@/core/vote';
import { getChainConstant } from '@/domain/chain';
import { SubgraphUrl } from '@/domain/subgraph';
import useChainId from '@/hooks/useChainId';
import querySubgraph from '@/infrastructure/subgraph';
import { useQuery } from '@tanstack/react-query';

const useEpochVotes = ({
  epochId,
  type,
}: {
  epochId?: string;
  type: Epoch['type'];
}) => {
  const chainId = useChainId();
  return useQuery({
    queryKey: ['epoch', epochId, 'votes', type, chainId],
    queryFn: async () => {
      const data = await querySubgraph({
        url: getChainConstant(SubgraphUrl, chainId),
        // only filter by epoch if type is NEW_COLLECTION_WAR
        // as for sweep wars the votes persist across epochs
        query: `query EpochVotes {
          users {
            id
            activeVotes(
              first: 1000
              where: {
                revoked: false
                ${
                  epochId && type === 'NEW_COLLECTION_WAR'
                    ? `epoch: "${epochId}"`
                    : ''
                }
                ${type ? ` type: ${type}` : ''}
              }) {
                id
                collection {
                  name
                  id
                }
                user {
                  id
                }
                revoked
                type
                votesCast
            }
          }
        }`,
      });

      return (data?.users || []) as Vote[];
    },
  });
};

export default useEpochVotes;
