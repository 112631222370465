import useCollection from '@/application/collections/useCollection';
import { getChainConstant } from '@/domain/chain';
import { Images } from '@/domain/images';
import useChainId from '@/hooks/useChainId';
import { Address } from 'viem';
import Blockie from '../Blockie';

export interface CollectionImageProps
  extends React.ImgHTMLAttributes<HTMLImageElement> {
  collection?: Address;
  diameter?: number;
}

const CollectionImage = ({
  collection,
  diameter,
  className,
  ...props
}: CollectionImageProps) => {
  const chainId = useChainId();
  const { data: collectionData } = useCollection(collection);

  if (!collection) {
    return <span className={`rounded-sm flex-none ${className ?? ''}`} />;
  }

  const path = getChainConstant(
    Images,
    chainId,
    collection.toLowerCase() as any,
  );

  if (path) {
    return (
      <img
        src={path}
        alt={collectionData?.name}
        className={`rounded-sm flex-none ${className ?? ''}`}
        {...props}
      />
    );
  }

  return (
    <Blockie
      address={collection}
      diameter={diameter}
      className={`${className ?? ''}`}
    />
  );
};

export default CollectionImage;
