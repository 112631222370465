import { getChainConstant } from '@/domain/chain';
import { SubgraphUrl } from '@/domain/subgraph';
import useChainId from '@/hooks/useChainId';
import querySubgraph from '@/infrastructure/subgraph';
import { useQuery } from '@tanstack/react-query';
import useCurrentEpoch from '../epochs/useCurrentEpoch';
import { LOCK_PERIODS } from '@/domain/stake';

const useTotalSweepPower = () => {
  const chainId = useChainId();
  const { data: currentEpoch } = useCurrentEpoch();

  return useQuery({
    queryKey: ['totalSweepPower', chainId, currentEpoch],
    queryFn: async () => {
      const data = (await querySubgraph({
        url: getChainConstant(SubgraphUrl, chainId),
        query: `query TotalSweepPower {
        voteLocks {
          lockStart
          lockEnd
          amount
        }
      }`,
      })) as {
        voteLocks: { lockStart: string; lockEnd: string; amount: string }[];
      };

      const totalSweepPower = data?.voteLocks?.reduce((acc, cur) => {
        const epochCount = BigInt(cur.lockEnd) - BigInt(cur.lockStart);
        const sweepPowerMultiplier =
          (epochCount * BigInt(1e18)) / LOCK_PERIODS[LOCK_PERIODS.length - 1];

        const sp = (BigInt(cur.amount) * sweepPowerMultiplier) / BigInt(1e18);

        return acc + sp;
      }, 0n);

      return totalSweepPower;
    },
    enabled: currentEpoch != null,
  });
};

export default useTotalSweepPower;
