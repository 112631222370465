import Button from '@/components/elements/Button';
import { Collection } from '@/core/collection';
import { ActiveVote } from '@/core/vote';
import {
  ArrowDownCircleIcon,
  ArrowUpCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useFormatter, useTranslations } from 'use-intl';
import { Address, Chain, formatEther, parseEther } from 'viem';
import ActiveVotes from './ActiveVotes';
import {
  ArrowPathIcon,
  ArrowRightIcon,
  CheckCircleIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import CollectionImage from '@/presentation/components/elements/CollectionImage';

export interface VoteProps {
  chain: Chain;
  collection?: Collection;
  voteDirection: 'for' | 'against';
  setVoteDirection: Dispatch<SetStateAction<'for' | 'against'>>;
  status: 'voted' | 'selected' | 'unselected';
  isVotePending: boolean;
  isVoteConfirming: boolean;
  isVoteConfirmed: boolean;
  voteError: Error | null;
  sweepPower?: bigint;
  remainingSweepPower?: bigint;
  collectionSweepPower?: bigint;
  activeVotes?: ActiveVote[];
  onVoteClick: (address: Address, amount: bigint) => void;
  onRevokeAllClick: () => void;
  revokeError: any;
  isRevokePending: boolean;
  isRevokeConfirmed: boolean;
  isRevokeConfirming: boolean;
  totalVotes: bigint;
  lockRemaining?: bigint | null;
  sweepAmount: bigint | null;
  winningSweepPowerUsed?: bigint;
  minSweepPowerNeededForSweep?: bigint | null;
  minSweepPowerNeededToRetainSweep?: bigint | null;
  isGettingSwept: boolean;
}

const getVoteAmount = (balance: bigint, percent: number) => {
  const percentAsEther = parseEther(`${percent}`);

  const amount = (balance * percentAsEther) / BigInt(1e18);

  return amount;
};

const Vote = ({
  chain,
  collection,
  status,
  isVoteConfirmed,
  isVoteConfirming,
  isVotePending,
  voteError,
  voteDirection,
  setVoteDirection,
  sweepPower,
  remainingSweepPower,
  collectionSweepPower,
  onVoteClick,
  onRevokeAllClick,
  isRevokeConfirmed,
  isRevokeConfirming,
  isRevokePending,
  revokeError,
  activeVotes,
  totalVotes,
  lockRemaining,
  sweepAmount,
  winningSweepPowerUsed,
  minSweepPowerNeededForSweep,
  minSweepPowerNeededToRetainSweep,
  isGettingSwept,
}: VoteProps) => {
  const [percentUsed, setPercentUsed] = useState(0);
  const [sweepPowerUsed, setSweepPowerUsed] = useState('0');
  const t = useTranslations('Epoch.Vote');
  const { number } = useFormatter();

  const voteAmount = parseEther(sweepPowerUsed);

  const voted = !!activeVotes?.length;

  // const collectionSweepPowerNumber = Number(
  //   formatEther(collectionSweepPower || 0n),
  // );

  const newCollectionSweepPower =
    voteDirection === 'for'
      ? voteAmount + (collectionSweepPower || 0n)
      : (collectionSweepPower || 0n) - voteAmount;

  // const voteAmountNumber = Number(formatEther(voteAmount));
  // const totalVotesNumber = Number(formatEther(totalVotes));
  // const winningSweepPowerUsedNumber = Number(
  //   formatEther(winningSweepPowerUsed || 1n),
  // );
  // const newCollectionSweepPowerNumber = Number(
  //   formatEther(newCollectionSweepPower),
  // );

  // const percentageChange =
  //   (voteDirection === 'for'
  //     ? voteAmountNumber + collectionSweepPowerNumber
  //     : collectionSweepPowerNumber - voteAmountNumber) /
  //   (voteAmountNumber + totalVotesNumber);

  // const collectionPercentage = collectionSweepPowerNumber / totalVotesNumber;

  const handlePercentChange = useCallback(
    (percent: number) => {
      setPercentUsed(percent);
      setSweepPowerUsed(
        formatEther(getVoteAmount(remainingSweepPower || 0n, percent)),
      );
    },
    [remainingSweepPower],
  );

  // const percentOfWinningVote = !!winningSweepPowerUsed
  //   ? collectionSweepPowerNumber / winningSweepPowerUsedNumber
  //   : 0;

  // if a collection is moving into the winning SP
  // we need to include this in the new total SP
  // TODO: refactor omg
  // const newWinningSweepPowerUsed =
  //   minSweepPowerNeededForSweep != null
  //     ? voteDirection === 'for'
  //       ? collectionSweepPowerNumber > minSweepPowerNeededForSweep // already being swept
  //         ? winningSweepPowerUsedNumber + voteAmountNumber
  //         : collectionSweepPowerNumber <= minSweepPowerNeededForSweep &&
  //             collectionSweepPowerNumber + voteAmountNumber >
  //               minSweepPowerNeededForSweep // collection moving into being swept
  //           ? winningSweepPowerUsedNumber + newCollectionSweepPowerNumber
  //           : winningSweepPowerUsedNumber
  //       : winningSweepPowerUsedNumber - voteAmountNumber
  //     : winningSweepPowerUsedNumber;

  // const percentOfWinningVoteNew = !!winningSweepPowerUsed
  //   ? voteDirection === 'for'
  //     ? (collectionSweepPowerNumber + voteAmountNumber) /
  //       newWinningSweepPowerUsed
  //     : (collectionSweepPowerNumber - voteAmountNumber) /
  //       newWinningSweepPowerUsed
  //   : 0;

  // const collectionSweepAmount = !!sweepAmount
  //   ? parseEther(`${Number(formatEther(sweepAmount)) * percentOfWinningVote}`)
  //   : undefined;

  // const collectionSweepAmountNew = !!sweepAmount
  //   ? parseEther(
  //       `${Number(formatEther(sweepAmount)) * percentOfWinningVoteNew}`,
  //     )
  //   : undefined;

  const arrowColor =
    voteDirection === 'for' ? 'text-brand-blue' : 'text-brand-red';

  return (
    <div className="rounded-xl bg-gradient-to-br from-brand-blue/40 via-brand-blue/10 to-brand-blue/10">
      <header className="flex items-center px-6 py-4 border-b border-white/20">
        <h3 className="text-sm font-medium">{'Vote'}</h3>
        {voted && (
          <aside className="ml-auto flex items-center space-x-4">
            <button
              type="button"
              className="text-xs inline-flex items-center  text-gray-200 text-white/50 hover:text-white/80"
              onClick={onRevokeAllClick}
              disabled={isRevokePending}
            >
              <span>{'Revoke All'}</span>
              {isRevokeConfirming ? (
                <ArrowPathIcon className="animate-spin h-5 w-5 ml-1" />
              ) : (
                <XMarkIcon className="w-5 h-5 ml-1" />
              )}
            </button>
          </aside>
        )}
      </header>
      <div className="p-6 space-y-4">
        {revokeError && (
          <div className="bg-red-500 text-white p-4 rounded-md text-xs">
            <h4 className="font-bold">{revokeError?.name}</h4>
            <pre className="text-xs overflow-x-scroll">
              {revokeError?.message}
            </pre>
          </div>
        )}
        {voted && <ActiveVotes activeVotes={activeVotes} />}
        {status === 'selected' && collection && (
          <>
            <div className="rounded-xl bg-black p-4">
              <div className=" flex items-center">
                {collection ? (
                  <CollectionImage
                    collection={collection.id}
                    diameter={64}
                    className="w-12 h-12 xl:w-16 xl:h-16 mr-4"
                  />
                ) : (
                  <span className="w-16 h-16 rounded-md bg-slate-500 mr-4" />
                )}
                <span className="truncate flex-1">{collection?.name}</span>
                <aside className="ml-auto text-xs space-y-2 text-right pl-4">
                  <h4 className="text-right text-gray-200">
                    {voted ? 'Remaining Sweep Power' : 'Sweep Power'}
                  </h4>
                  <div className="flex items-center justify-end">
                    <input
                      type="number"
                      // value={number((percentUsed || 0) * 100)}
                      value={sweepPowerUsed}
                      step={0.1}
                      min={0}
                      max={100}
                      className="text-right bg-black border border-gray-400 rounded-lg py-1 px-2 w-full max-w-[8rem] text-base font-normal mr-1 focus:ring-2 focus:ring-brand-blue/25 focus:outline-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                      onChange={(e) => {
                        setSweepPowerUsed(e.currentTarget.value);
                      }}
                    />
                  </div>
                  <div className="text-white text-right whitespace-nowrap">
                    <button
                      onClick={() => {
                        setSweepPowerUsed(
                          formatEther(remainingSweepPower || 0n),
                        );

                        setPercentUsed(1);
                      }}
                      className="hover:underline text-right"
                    >
                      {'Available: '}
                      {number(Number(formatEther(remainingSweepPower || 0n)))}
                      {' / '}
                      {number(Number(formatEther(sweepPower || 0n)))}
                      {' SP'}
                    </button>
                  </div>
                </aside>
              </div>
              <div className="grid grid-cols-5 gap-2 mt-4">
                {[0.25, 0.5, 0.75, 1].map((percent) => (
                  <button
                    type="button"
                    key={percent}
                    className={`border rounded-lg text-xs px-1 py-1 text-center ${
                      percent === percentUsed
                        ? 'text-white border-white'
                        : 'text-gray-200 hover:text-white/80 border-gray-400'
                    }`}
                    onClick={() => handlePercentChange(percent)}
                  >
                    {number(percent * 100, {
                      maximumFractionDigits: 0,
                    })}
                    {'%'}
                  </button>
                ))}
                <span className="flex items-center relative">
                  <input
                    type="number"
                    value={
                      ![0.25, 0.5, 0.75, 1].some((x) => x === percentUsed)
                        ? number((percentUsed || 0) * 100)
                        : ''
                    }
                    step={0.1}
                    min={0}
                    max={100}
                    placeholder="--"
                    className={`bg-black border ${
                      ![0.25, 0.5, 0.75, 1].some((x) => x === percentUsed)
                        ? 'border-white'
                        : 'border-gray-400'
                    } rounded-lg py-1 pl-2 pr-4 flex-grow min-w-0 text-xs font-normal focus:ring-2 focus:ring-brand-blue/25 focus:outline-none [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`}
                    onChange={(e) => {
                      handlePercentChange(
                        Math.min((Number(e.currentTarget.value) || 0) / 100, 1),
                      );
                    }}
                  />
                  <span className="text-xs absolute right-1">{'%'}</span>
                </span>
              </div>
            </div>
            <div className="rounded-xl bg-black px-4 py-2 flex items-center justify-between text-sm">
              <h4 className="text-gray-200">{'Using'}</h4>
              <span className="text-white">
                {number(Number(formatEther(voteAmount)))}
                {' SP '}
              </span>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <button
                type="button"
                onClick={() => setVoteDirection('for')}
                className={`border rounded-xl text-xs px-3 py-2 flex items-center transition-colors ${
                  voteDirection === 'for'
                    ? 'border-brand-blue text-white'
                    : 'text-gray-200 border-gray-200 hover:border-brand-blue/50'
                }`}
              >
                <ArrowUpCircleIcon
                  className={`h-5 w-5  ${
                    voteDirection === 'for'
                      ? 'opacity-100 text-brand-blue'
                      : 'opacity-40 text-white'
                  }`}
                />
                <span className="flex-1 truncate">{'Vote Sweep'}</span>
                <span className="w-5" />
              </button>
              <button
                type="button"
                onClick={() => setVoteDirection('against')}
                className={`border rounded-xl text-xs px-3 py-2 flex items-center transition-colors ${
                  voteDirection === 'against'
                    ? 'border-brand-red text-white'
                    : 'text-gray-200 border-gray-200 hover:border-brand-red/50'
                }`}
              >
                <ArrowDownCircleIcon
                  className={`h-5 w-5  ${
                    voteDirection === 'against'
                      ? 'opacity-100 text-brand-red'
                      : 'opacity-40 text-white'
                  }`}
                />
                <span className="flex-1 truncate">{'Vote Sell'}</span>
                <span className="w-5" />
              </button>
            </div>
            <dl className="px-4 text-xs space-y-2">
              <div className="flex items-start">
                <dt className="text-gray-200">{t('sweepPower')}</dt>
                <dd className="text-right flex-1 ml-4 space-y-1">
                  <span className="flex items-center justify-end space-x-1 font-bold">
                    <span>
                      {number(Number(formatEther(collectionSweepPower || 0n)), {
                        maximumFractionDigits: 0,
                      })}
                    </span>
                    <ArrowRightIcon className={`h-3 w-3 ${arrowColor}`} />
                    <span>
                      {number(Number(formatEther(newCollectionSweepPower)), {
                        maximumFractionDigits: 0,
                      })}
                    </span>
                  </span>
                </dd>
              </div>
              {/* <div className="flex items-start">
                <dt className="text-gray-200">{t('sweepSize')}</dt>
                {minSweepPowerNeededForSweep != null &&
                newCollectionSweepPower &&
                newCollectionSweepPower > minSweepPowerNeededForSweep ? (
                  <dd className="text-right flex-1 ml-4 space-y-1">
                    <span className="flex items-center justify-end space-x-1 font-bold">
                      <span>
                        {isGettingSwept ? (
                          <Price value={collectionSweepAmount} />
                        ) : (
                          0
                        )}
                      </span>
                      <ArrowRightIcon className={`h-3 w-3 ${arrowColor}`} />
                      <span>
                        {!isGettingSwept ||
                        (isGettingSwept &&
                          newCollectionSweepPower &&
                          minSweepPowerNeededToRetainSweep &&
                          newCollectionSweepPower >
                            minSweepPowerNeededToRetainSweep) ? (
                          <Price value={collectionSweepAmountNew} />
                        ) : (
                          0
                        )}
                      </span>
                    </span>
                    <span className="flex items-center justify-end space-x-1 text-gray-200">
                      <span>
                        {isGettingSwept
                          ? number(percentOfWinningVote || 0, {
                              maximumFractionDigits: 2,
                              style: 'percent',
                            })
                          : `0%`}
                      </span>
                      <ArrowRightIcon className={`h-3 w-3 ${arrowColor}`} />
                      <span>
                        {number(percentOfWinningVoteNew || 0, {
                          maximumFractionDigits: 2,
                          style: 'percent',
                        })}
                      </span>
                    </span>
                  </dd>
                ) : (
                  <dd className="text-right flex-1 ml-4">{'-'}</dd>
                )}
              </div> */}
            </dl>

            <Button
              className="w-full"
              variant={voteDirection === 'against' ? 'tertiary' : 'primary'}
              onClick={() => onVoteClick(collection.id, voteAmount)}
              processing={isVoteConfirming}
              disabled={
                isVotePending ||
                voteAmount === 0n ||
                (sweepPower != null && voteAmount > sweepPower)
              }
            >
              {'Cast Vote'}
            </Button>

            {isVoteConfirmed && (
              <div className="bg-black/30 text-white p-4 rounded-md text-xs flex items-center">
                <CheckCircleIcon className="h-6 w-6 text-white mr-2" />
                <div>
                  <h4 className="font-bold">{'Success'}</h4>
                  <p>{'Your vote was cast.'}</p>
                </div>
              </div>
            )}
            {voteError && (
              <div className="bg-red-500 text-white p-4 rounded-md text-xs">
                <h4 className="font-bold">{voteError?.name}</h4>
                <pre className="text-xs overflow-x-scroll">
                  {voteError?.message}
                </pre>
              </div>
            )}
          </>
        )}
        {status === 'unselected' && (
          <p className="py-6 text-center text-gray-200 text-xs">
            {'Select a collection'}
          </p>
        )}
      </div>
    </div>
  );
};

export default Vote;
