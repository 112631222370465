import { ActiveVote } from '@/core/vote';
import User from '@/presentation/components/elements/User';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import Link from 'next/link';
import { useState } from 'react';
import { useFormatter, useTranslations } from 'use-intl';
import { Chain, formatEther } from 'viem';

const PAGE_SIZE = 10;

const Voters = ({
  votes = [],
  chain,
}: {
  votes?: ActiveVote[];
  chain: Chain;
}) => {
  const [offset, setOffset] = useState(0);
  const t = useTranslations('Epoch.Voters');
  const { number } = useFormatter();

  const visibleVotes = votes?.slice(offset, offset + PAGE_SIZE);

  const totalPages = Math.ceil(votes.length / PAGE_SIZE);
  const currentPage = Math.floor(offset / PAGE_SIZE) + 1;

  const handleNext = () => {
    setOffset((prevOffset) => {
      const newOffset = Math.min(
        prevOffset + PAGE_SIZE,
        (totalPages - 1) * PAGE_SIZE,
      );
      return newOffset;
    });
  };

  const handlePrevious = () => {
    setOffset((prevOffset) => {
      const newOffset = Math.max(prevOffset - PAGE_SIZE, 0);
      return newOffset;
    });
  };

  return (
    <div className="p-4 overflow-x-scroll hide-scroll">
      <table className="w-full">
        <thead className="text-gray-200 text-left text-xxs">
          <tr>
            <th className="py-1 pr-2 font-medium">{t('table.user')}</th>
            <th className="py-1 px-2 font-medium">{t('table.collection')}</th>
            <th className="py-1 pl-2 font-medium text-right">
              {t('table.power')}
            </th>
          </tr>
        </thead>
        <tbody className="text-xs align-top">
          {visibleVotes?.map((x) => (
            <tr key={x.id}>
              <td className="py-1 pr-2">
                <hgroup>
                  <h3 className="flex items-center">
                    <span
                      className={`${
                        BigInt(x.votesCast) < 0n
                          ? 'bg-brand-red'
                          : 'bg-brand-green'
                      } rounded-full h-1.5 w-1.5 mr-2`}
                    />
                    <Link
                      href={`${chain.blockExplorers?.default.url}/address/${x.user.id}`}
                      target="_blank"
                      rel="nofollow noreferrer"
                      className="hover:underline"
                    >
                      <span className="truncate">
                        <User address={x.user.id} />
                      </span>
                    </Link>
                  </h3>
                </hgroup>
              </td>
              <td className="py-1 px-2">
                <span className="break-all">{x.collection.name}</span>
              </td>
              <td className="py-1 pl-2 text-right">
                {number(Number(formatEther(BigInt(x.votesCast))), {
                  signDisplay: 'never',
                  maximumFractionDigits: 2,
                })}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {votes.length > PAGE_SIZE && (
        <div className="flex items-center mt-4">
          <button
            onClick={handlePrevious}
            type="button"
            disabled={offset === 0}
            className="disabled:opacity-40"
          >
            <ChevronLeftIcon className="text-white h-4 w-4" />
          </button>
          <div className="flex-1 text-xxs text-center text-gray-200">
            {`Page ${currentPage} of ${totalPages}`}
          </div>
          <button
            onClick={handleNext}
            type="button"
            disabled={offset + PAGE_SIZE >= votes.length}
            className="disabled:opacity-40"
          >
            <ChevronRightIcon className="text-white h-4 w-4" />
          </button>
        </div>
      )}
    </div>
  );
};

export default Voters;
