import { Epoch } from '@/core/epoch';
import { ActiveVote } from '@/core/vote';
import { getChainConstant } from '@/domain/chain';
import { SubgraphUrl } from '@/domain/subgraph';
import useChainId from '@/hooks/useChainId';
import querySubgraph from '@/infrastructure/subgraph';
import { useQuery } from '@tanstack/react-query';
import { Address } from 'viem';

const useUserActiveVotes = ({
  userAddress,
  epochId,
  type,
}: {
  userAddress?: Address;
  epochId: string;
  type?: Epoch['type'];
}) => {
  const chainId = useChainId();

  return useQuery({
    queryKey: ['userActiveVotes', epochId, userAddress, type, chainId],
    queryFn: async () => {
      const data = (await querySubgraph({
        url: getChainConstant(SubgraphUrl, chainId),
        query: `query UserActiveVotes {
        user(id: "${userAddress?.toLowerCase()}") {
          activeVotes(where: { type: ${type} revoked: false ${
            type === 'NEW_COLLECTION_WAR' ? `epoch: "${epochId}"` : ''
          } }) {
            id
            collection {
              id
            }
            revoked
            epoch {
              id
            }
            type
            votesCast
          }
        }
      }`,
      })) as any;

      return (data?.user?.activeVotes || []) as ActiveVote[];
    },
    enabled: !!userAddress && !!type,
    refetchInterval: 15000,
  });
};

export default useUserActiveVotes;
