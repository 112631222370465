import { usePublicClient } from 'wagmi';
import QuoterAbi from '@/infrastructure/abis/UniswapQuoterV2Abi';
import { getChainConstant } from '@/domain/chain';
import useChainId from '@/hooks/useChainId';
import { Addresses } from '@/domain/addresses';
import { useQuery } from '@tanstack/react-query';

const useEthereumPrice = () => {
  const chainId = useChainId();
  const client = usePublicClient();

  return useQuery({
    queryKey: ['ethereumPrice', chainId],
    queryFn: async () => {
      const call = await client?.simulateContract({
        abi: QuoterAbi,
        address: getChainConstant(Addresses, chainId, 'UniswapQuoter'),
        functionName: 'quoteExactOutputSingle',
        args: [
          {
            amount: BigInt(1e18),
            fee: chainId === 1 ? 3000 : 100,
            sqrtPriceLimitX96: 0n,
            tokenIn: getChainConstant(Addresses, chainId, 'USDC'),
            tokenOut: getChainConstant(Addresses, chainId, 'WETH'),
          },
        ],
      });

      return call?.result[0];
    },
    enabled: !!client,
  });
};

export default useEthereumPrice;
