import useActivity from '@/application/epochs/useActivity';
import Activity from './Activity';

const ActivityContainer = () => {
  const { data: activity } = useActivity();

  console.log(activity);
  return <Activity activity={activity} />;
};

export default ActivityContainer;
