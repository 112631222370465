import { getChainConstant } from '@/domain/chain';
import { SubgraphUrl } from '@/domain/subgraph';
import useChainId from '@/hooks/useChainId';
import querySubgraph from '@/infrastructure/subgraph';
import { useQuery } from '@tanstack/react-query';
import { absBigInt } from '@/utils/bigint';
import { Epoch } from '@/core/epoch';

const useTotalSweepPowerUsed = ({
  epochId,
  type,
}: {
  epochId: string;
  type?: Epoch['type'];
}) => {
  const chainId = useChainId();

  return useQuery({
    queryKey: ['totalSweepPowerUsed', chainId, epochId, type],
    queryFn: async () => {
      try {
        const data = (await querySubgraph({
          url: getChainConstant(SubgraphUrl, chainId),
          query: `query TotalSweepPowerUsed {
            users(first: 1000 where:{
              activeVotes_: {
                type: ${type}
                ${
                  type === 'NEW_COLLECTION_WAR'
                    ? `epoch: "${epochId}"`
                    : 'revoked: false'
                }
              }
            }) {
              activeVotes(where:{
                revoked: false
                type: ${type}
              }) {
                votesCast
              }
              activeVotes {
                votesCast
              }
              voteLock {
                lockStart
                lockEnd
                amount
              }
            }
          }`,
        })) as {
          users: {
            activeVotes: [{ votesCast: string }];
            voteLock: { lockStart: string; lockEnd: string; amount: string };
          }[];
        };

        const totalSweepPowerUsed = data?.users?.reduce((acc, cur) => {
          const totalVotesCast =
            type === 'SWEEP_WAR'
              ? cur.activeVotes.reduce(
                  (sum, vote) => sum + absBigInt(BigInt(vote.votesCast)),
                  0n,
                )
              : BigInt(cur.voteLock.amount);

          return acc + totalVotesCast;
        }, 0n);

        return totalSweepPowerUsed;
      } catch (e) {
        console.error(e);
      }
    },
    enabled: epochId != null && type != null,
  });
};

export default useTotalSweepPowerUsed;
