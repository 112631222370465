'use client';
import { useAccount } from 'wagmi';
import Disconnected from './Disconnected';
import VoteNewCollection from './VoteNewCollection';
import { useCallback, useMemo } from 'react';
import ConnectButton from '@/presentation/components/modules/ConnectButton';
import useCollection from '@/application/collections/useCollection';
import useSweepPower from '@/application/staking/useSweepPower';
import useUserActiveVotes from '@/application/epochs/useUserActiveVotes';
import useEpoch from '@/application/epochs/useEpoch';
import useVoteOnCollectionWar from '@/application/epochs/useVoteOnCollectionWar';
import { Address } from 'viem';
import { useChain } from '@/hooks/useChain';

const VoteNewCollectionContainer = ({
  collectionId,
  epochId,
}: {
  collectionId?: string;
  epochId: string;
}) => {
  const chain = useChain();
  const { isConnected, address } = useAccount();
  const { data: sweepPower } = useSweepPower({ address, watch: true });
  const { data: collection } = useCollection(collectionId);
  const { data: epoch } = useEpoch(epochId);
  const { data: userActiveVotes } = useUserActiveVotes({
    userAddress: address,
    type: epoch?.type,
    epochId,
  });

  const {
    vote,
    isPending,
    isConfirming,
    isConfirmed,
    error: voteError,
  } = useVoteOnCollectionWar();

  const status = useMemo(() => {
    if (!isConnected) return 'disconnected';
    if (collectionId) return 'selected';

    return 'unselected';
  }, [isConnected, collectionId]);

  const handleVote = useCallback(
    (collectionAddress: Address) => vote(collectionAddress),
    [vote],
  );

  if (status === 'disconnected') {
    return <Disconnected connect={<ConnectButton className="w-full" />} />;
  }

  const collectionSweepPower = (() => {
    const c = epoch?.newCollections?.find(
      (c) => c.collection.id === collectionId,
    );
    return BigInt(c?.votesFor || 0);
  })();

  const totalVotes =
    epoch?.newCollections?.reduce((acc, cur) => {
      return acc + BigInt(cur.votesFor);
    }, 0n) || 0n;

  return (
    <VoteNewCollection
      chain={chain}
      collection={collection}
      status={status}
      isPending={isPending}
      isConfirming={isConfirming}
      isConfirmed={isConfirmed}
      voteError={voteError}
      sweepPower={sweepPower}
      collectionSweepPower={collectionSweepPower}
      totalVotes={totalVotes}
      onVoteClick={handleVote}
      activeVotes={userActiveVotes}
    />
  );
};

export default VoteNewCollectionContainer;
