import Button from '@/components/elements/Button';
import { Collection } from '@/core/collection';
import { ActiveVote } from '@/core/vote';
import { useFormatter, useTranslations } from 'use-intl';
import { Address, Chain, formatEther } from 'viem';
import ActiveVotes from './ActiveVotes';
import { ArrowRightIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import CollectionImage from '@/presentation/components/elements/CollectionImage';

export interface VoteNewCollectionProps {
  chain: Chain;
  collection?: Collection;
  status: 'voted' | 'selected' | 'unselected';
  isPending: boolean;
  isConfirming: boolean;
  isConfirmed: boolean;
  voteError: Error | null;
  sweepPower?: bigint;
  totalVotes: bigint;
  collectionSweepPower?: bigint;
  activeVotes?: ActiveVote[];
  onVoteClick: (address: Address, amount: bigint) => void;
}

const VoteNewCollection = ({
  chain,
  collection,
  status,
  isPending,
  isConfirming,
  isConfirmed,
  voteError,
  sweepPower,
  collectionSweepPower,
  onVoteClick,
  activeVotes,
  totalVotes,
}: VoteNewCollectionProps) => {
  const t = useTranslations('Epoch.Vote');
  const { number } = useFormatter();

  const voteAmount = sweepPower || 0n;

  const voted = !!activeVotes?.length;

  const votedForCollection = activeVotes?.[0]?.collection.id === collection?.id;

  const collectionSweepPowerNumber = Number(
    formatEther(collectionSweepPower || 0n),
  );
  const totalVotesNumber = Number(formatEther(totalVotes));
  const voteAmountNumber = Number(formatEther(voteAmount));

  const collectionPercentage = collectionSweepPowerNumber / totalVotesNumber;

  const percentageChange =
    (voteAmountNumber + collectionSweepPowerNumber) /
    (totalVotesNumber + voteAmountNumber);

  return (
    <div className="rounded-xl bg-gradient-to-br from-brand-blue/40 via-brand-blue/10 to-brand-blue/10">
      <header className="flex items-center px-6 py-4 border-b border-white/20">
        <h3 className="text-sm font-medium">{'Vote'}</h3>
        {/* {voted && (
          <aside className="ml-auto flex items-center space-x-4">
            <button
              type="button"
              className="text-xs inline-flex items-center  text-gray-200 text-white/50 hover:text-white/80"
            >
              {'Revoke All'}
              <XMarkIcon className=" h-5 w-5 ml-1" />
            </button>
          </aside>
        )} */}
      </header>
      <div className="p-6 space-y-4">
        {voted && <ActiveVotes activeVotes={activeVotes} />}
        {status === 'selected' && collection && (
          <>
            <div className="rounded-xl bg-black p-4 flex items-center">
              {collection ? (
                <CollectionImage
                  collection={collection.id}
                  diameter={64}
                  className="w-16 h-16"
                />
              ) : (
                <span className="w-16 h-16 rounded-md bg-slate-500" />
              )}
              <span className="mx-4">{collection?.name}</span>
            </div>
            {!votedForCollection && (
              <>
                <div className="rounded-xl bg-black px-4 py-2 flex items-center justify-between text-sm">
                  <h4 className="text-gray-200">{'Using'}</h4>
                  <span className="text-white">
                    {number(voteAmountNumber)}
                    {' SP'}
                  </span>
                </div>
                <dl className="px-4 text-xs">
                  <div className="flex items-start">
                    <dt className="text-gray-200">{t('sweepPower')}</dt>
                    <dd className="text-right flex-1 ml-4 space-y-1">
                      <span className="flex items-center justify-end space-x-1 font-bold">
                        <span>
                          {number(
                            Number(formatEther(collectionSweepPower || 0n)),
                            {
                              maximumFractionDigits: 2,
                            },
                          )}
                        </span>
                        <ArrowRightIcon className="h-3 w-3 text-brand-blue" />
                        <span>
                          {number(
                            Number(
                              formatEther(
                                (collectionSweepPower || 0n) +
                                  (voteAmount || 0n),
                              ),
                            ),
                            {
                              maximumFractionDigits: 2,
                            },
                          )}
                        </span>
                      </span>
                      <span className="flex items-center justify-end space-x-1 text-gray-200">
                        <span>
                          {number(collectionPercentage || 0, {
                            maximumFractionDigits: 2,
                            style: 'percent',
                          })}
                        </span>
                        <ArrowRightIcon className="h-3 w-3 text-brand-blue" />
                        <span>
                          {number(percentageChange || 0, {
                            maximumFractionDigits: 2,
                            style: 'percent',
                          })}
                        </span>
                      </span>
                    </dd>
                  </div>
                </dl>
              </>
            )}

            <Button
              className="w-full"
              onClick={() => onVoteClick(collection.id, voteAmount)}
              processing={isConfirming}
              disabled={
                votedForCollection ||
                isPending ||
                voteAmount === 0n ||
                isConfirmed
              }
            >
              {votedForCollection ? (
                'Already Voted'
              ) : isConfirmed ? (
                <>
                  {'Vote Cast'} <CheckCircleIcon className="w-5 h-5 ml-1" />
                </>
              ) : voted ? (
                'Switch Vote'
              ) : (
                'Cast Vote'
              )}
            </Button>

            {voteError && (
              <div className="bg-red-500 text-white p-4 rounded-md text-xs">
                <h4 className="font-bold">{voteError?.name}</h4>
                <pre className="text-xs overflow-x-scroll">
                  {voteError?.message}
                </pre>
              </div>
            )}
          </>
        )}
        {status === 'unselected' && !voted && (
          <p className="py-6 text-center text-gray-200 text-xs">
            {'Select a collection'}
          </p>
        )}
      </div>
    </div>
  );
};

export default VoteNewCollection;
