import { Epoch, EpochCollection, Epochs } from '@/core/epoch';
import CollectionImage from '@/presentation/components/elements/CollectionImage';
import Price from '@/presentation/components/elements/Price';
import Tooltip from '@/presentation/components/elements/Tooltip';
import { absBigInt } from '@/utils/bigint';
import { ArrowUpIcon } from '@heroicons/react/24/outline';
import { ArrowDownIcon } from '@heroicons/react/24/solid';
import { Fragment } from 'react';
import { useFormatter, useTranslations } from 'use-intl';
import { Chain, formatEther, parseEther } from 'viem';

type TableRowSweepProps = EpochCollection & {
  chain: Chain;
  collectionId: any;
  previousCollections?: Epochs[0]['newCollections'] | Epochs[0]['collections'];
  onCollectionClick: (c: any) => void;
  sampleSize?: Epoch['sampleSize'];
  totalSweepPowerUsed?: bigint;
  winningSweepPowerUsed?: bigint;
  sweepAmount: bigint | null;
  index: number;
  viewOnly: boolean;
};

const TableRowSweep = ({
  chain,
  previousCollections,
  winningSweepPowerUsed,
  totalSweepPowerUsed,
  sweepAmount,
  sampleSize,
  votesFor,
  votesAgainst,
  votedFor,
  votedAgainst,
  id,
  collection,
  index,
  onCollectionClick,
  collectionId,
  viewOnly,
}: TableRowSweepProps) => {
  const t = useTranslations('Epoch.Table');
  const { number } = useFormatter();
  const positionLastTime = previousCollections
    ? previousCollections.findIndex(
        (x) => x.collection.id.toLowerCase() === collection.id.toLowerCase(),
      )
    : -1;

  const sweepPower = BigInt(votesFor) - BigInt(votesAgainst || 0);

  const percentOfWinningVote = !!winningSweepPowerUsed
    ? Number(
        formatEther(
          (absBigInt(sweepPower) * BigInt(1e18)) /
            (winningSweepPowerUsed || 1n),
        ),
      )
    : 0;

  const percentOfWinningSweepPower = !!totalSweepPowerUsed
    ? Number(
        formatEther(
          (absBigInt(sweepPower) * BigInt(1e18)) /
            (winningSweepPowerUsed || 1n),
        ),
      )
    : 0;

  const percentOfSweepPower = !!totalSweepPowerUsed
    ? Number(
        formatEther(
          (absBigInt(sweepPower) * BigInt(1e18)) / (totalSweepPowerUsed || 1n),
        ),
      )
    : 0;

  const collectionSweepAmount = !!sweepAmount
    ? parseEther(`${Number(formatEther(sweepAmount)) * percentOfWinningVote}`)
    : undefined;

  const isInPromotion = index < Number(sampleSize);

  const isInRelegation = sweepPower < 0n;

  const isSelected = collectionId === collection.id;

  const numberVotedFor = Number(votedFor || 0);
  const numberVotedAgainst = Number(votedAgainst || 0);

  const numberVotesFor = Number(votesFor || 0);
  const numberVotesAgainst = Number(votesAgainst || 0);

  return (
    <Fragment key={id}>
      {index === Number(sampleSize) ? (
        <tr key={`winners`}>
          <td colSpan={5} className="border-t-2 border-brand-blue"></td>
        </tr>
      ) : null}
      <tr
        onClick={() => !viewOnly && onCollectionClick(collection.id)}
        className={`${!viewOnly ? 'cursor-pointer' : ''} ${
          isInPromotion
            ? isSelected
              ? 'bg-brand-blue/30'
              : 'bg-brand-blue/10 hover:bg-brand-blue/20'
            : isInRelegation
              ? isSelected
                ? 'bg-brand-red/50'
                : 'bg-brand-red/30 hover:bg-brand-red/40'
              : isSelected
                ? 'bg-black'
                : 'hover:bg-gray-600 bg-gray-800'
        } `}
      >
        <td className={`pl-2 pr-4 py-2`}>
          <div className="flex items-center">
            <span className="mr-4 inline-flex items-center space-x-1">
              {positionLastTime > -1 ? (
                positionLastTime === index ? (
                  <span className="w-4" />
                ) : positionLastTime > index ? (
                  <ArrowUpIcon className="text-brand-green h-4 w-4" />
                ) : (
                  <ArrowDownIcon className="text-brand-red h-4 w-4" />
                )
              ) : (
                <span className="w-4" />
              )}
              <span className="w-4">{index + 1}</span>
            </span>
            {id ? (
              <CollectionImage
                collection={collection.id}
                className="h-12 w-12"
                diameter={48}
              />
            ) : (
              <span className="w-12 h-12 bg-brand-pink rounded-sm inline-flex" />
            )}
            <span className="ml-4 truncate">{collection.name}</span>
          </div>
        </td>
        <td className="px-4 text-right">
          <Tooltip
            labelClassName="py-4 block"
            content={
              <table className="text-right text-xs">
                <thead>
                  <tr>
                    <th className="py-0.5 px-2">{''}</th>
                    <th className="py-0.5 px-2">{'Count'}</th>
                    <th className="py-0.5 px-2">{'Amount'}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="p-0.5 px-2 text-left text-brand-blue">
                      {t('votes.buy')}
                    </td>
                    <td className="p-0.5 px-2">{number(numberVotedFor)}</td>
                    <td className="p-0.5 px-2">
                      {number(Number(formatEther(BigInt(votesFor))), {
                        notation: 'compact',
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td className="p-0.5 px-2 text-left text-brand-red">
                      {t('votes.sell')}
                    </td>
                    <td className="p-0.5 px-2">{number(numberVotedAgainst)}</td>
                    <td className="p-0.5 px-2">
                      {number(Number(formatEther(BigInt(votesAgainst))), {
                        notation: 'compact',
                      })}
                    </td>
                  </tr>
                </tbody>
              </table>
            }
            label={
              numberVotesFor + numberVotesAgainst > 0 ? (
                <div className="h-1 w-full rounded-full overflow-hidden bg-brand-blue flex justify-end">
                  <span
                    className="bg-brand-red"
                    style={{
                      width: `${(numberVotesAgainst / (numberVotesFor + numberVotesAgainst)) * 100}%`,
                    }}
                  />
                </div>
              ) : (
                <div className="h-1 w-full rounded-full overflow-hidden bg-gray-400" />
              )
            }
          />
        </td>
        <td className="px-4 py-2 text-right">
          {number(Number(formatEther(sweepPower)), {
            maximumFractionDigits: 0,
            signDisplay: 'negative',
          })}
        </td>
        <td className="px-4 py-2 text-right">
          {sweepAmount && isInPromotion ? (
            <Price value={collectionSweepAmount} />
          ) : (
            '-'
          )}
          <span className="ml-1 text-sm text-gray-200">
            {isInPromotion
              ? `(${number(percentOfWinningSweepPower, {
                  style: 'percent',
                  maximumFractionDigits: 1,
                })})`
              : ''}
            {isInRelegation
              ? `(${number(percentOfSweepPower, {
                  style: 'percent',
                  maximumFractionDigits: 1,
                })})`
              : ''}
          </span>
        </td>
      </tr>
    </Fragment>
  );
};

export default TableRowSweep;
