import { Dispatch, SetStateAction, useState } from 'react';
import InfoBar from './InfoBar';
import VoteSweep from './VoteSweep';
import Voters from './Voters';
import type { Epoch, Epochs } from '@/core/epoch';
import VoteNewCollection from './VoteNewCollection';
import {
  ArrowTopRightOnSquareIcon,
  ArrowUpIcon,
} from '@heroicons/react/24/outline';
import { getChainConstant } from '@/domain/chain';
import { Addresses } from '@/domain/addresses';
import TableSweep from './TableSweep';
import TableNewCollection from './TableNewCollection';
import Activity from './Activity';

const Epoch = ({
  epochs,
  selected,
  setSelected,
  collection,
  setCollection,
  sweepStatus,
  type,
  chainId,
}: {
  chainId: number;
  epochs: Epochs;
  selected: any;
  setSelected: Dispatch<SetStateAction<any>>;
  collection: any;
  setCollection: Dispatch<SetStateAction<any>>;
  sweepStatus?: 'PENDING' | 'SWEPT' | 'LIVE';
  type: Epoch['type'];
}) => {
  const [tab, setTab] = useState<'voters' | 'activity'>('voters');
  return (
    <div className="py-4 space-y-4">
      <InfoBar epochs={epochs} selected={selected} setSelected={setSelected} />
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        <section
          className={`${
            sweepStatus === 'LIVE'
              ? 'lg:col-span-2'
              : 'md:col-span-2 lg:col-span-3'
          }`}
        >
          {type === 'SWEEP_WAR' && (
            <TableSweep
              setCollection={setCollection}
              collectionId={collection}
              epochId={selected}
            />
          )}
          {type === 'NEW_COLLECTION_WAR' && (
            <TableNewCollection
              epochId={selected}
              collectionId={collection}
              setCollection={setCollection}
            />
          )}
        </section>
        {sweepStatus === 'LIVE' && (
          <aside className="lg:col-span-1 space-y-4">
            <>
              {type === 'NEW_COLLECTION_WAR' && (
                <VoteNewCollection
                  collectionId={collection}
                  epochId={selected}
                />
              )}
              {type === 'SWEEP_WAR' && (
                <VoteSweep collectionId={collection} epochId={selected} />
              )}
            </>
            <div className="bg-gray-800 rounded-xl">
              <header className="border-b border-gray-400 px-4 flex space-x-4">
                {(['voters', 'activity'] as const).map((k) => (
                  <button
                    key={k}
                    type="button"
                    className={`p-4 border-b ${k === tab ? 'border-white text-white' : 'border-transparent text-gray-200 hover:text-gray-100'} -mb-px text-xs`}
                    onClick={() => setTab(k)}
                  >
                    {k === 'voters' ? 'Voters' : 'Activity'}
                  </button>
                ))}
              </header>
              {tab === 'voters' && <Voters epochId={selected} type={type} />}
              {tab === 'activity' && <Activity />}
            </div>
          </aside>
        )}
      </div>
    </div>
  );
};

export default Epoch;
