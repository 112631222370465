import { Epochs } from '@/core/epoch';
import { Sweep } from '@/core/sweep';
import EpochStatus from '@/presentation/components/elements/EpochStatus/EpochStatus';
import { ReactNode } from 'react';
import { useFormatter, useTranslations } from 'use-intl';
import { Address, Chain } from 'viem';
import StrategyYieldTable from './StrategyYieldTable';
import CircularProgress from './Progress';
import Price from '@/presentation/components/elements/Price';

export interface InfoBarProps {
  currentYield: bigint;
  minSweep: bigint;
  progress: number;
  ends: number;
  chain: Chain;
  selectEpoch: ReactNode;
  now: number;
  status: Sweep['status'] | 'LIVE';
  sweepSize: bigint;
  sweepTx?: string;
  epochs: Epochs;
  strategy?: Address[];
  strategyYield?: bigint[];
  participation?: number;
}

const InfoBar = ({
  currentYield,
  minSweep,
  progress,
  ends,
  chain,
  selectEpoch,
  now,
  status,
  sweepSize,
  sweepTx,
  strategy = [],
  strategyYield = [],
  participation,
}: InfoBarProps) => {
  const t = useTranslations('Epoch.InfoBar');
  const { number, relativeTime, dateTime } = useFormatter();

  const diff = ends - now;

  // seconds under 1 minute, minutes if under 1 hour, hours if under 1 day, days if under 2 weeks
  const unit =
    diff < 60_000
      ? 'seconds'
      : diff < 3_600_000
        ? 'minutes'
        : diff < 86_400_000
          ? 'hours'
          : diff < 1_296_000_000
            ? 'days'
            : 'weeks';

  return (
    <div className="rounded-xl bg-gray-800 py-2 md:py-4 space-y-4 md:space-y-0 lg:flex">
      <section className="px-2 md:px-4 md:flex md:items-center space-y-4 md:space-x-4 md:space-y-0 lg:border-r border-gray-400">
        {selectEpoch}
        <dl className="px-2 flex lg:flex-col xl:items-center xl:flex-row space-x-4 lg:space-y-2 lg:space-x-0 xl:space-x-4 xl:space-y-0">
          <div className="">
            <dd className="font-display text-brand-blue flex items-baseline">
              <Price
                value={status === 'LIVE' ? currentYield : sweepSize}
                symbolClassName="text-xxs ml-1"
              />
            </dd>
            <dt className="text-gray-200 text-xs">
              {t(status === 'LIVE' ? 'current' : 'sweepSize')}
            </dt>
          </div>

          {status !== 'SWEPT' && (
            <div>
              <dd className="font-display text-brand-blue flex items-baseline">
                <Price value={minSweep} symbolClassName="text-xxs ml-1" />
              </dd>
              <dt className="text-gray-200 text-xs">{t('sweep')}</dt>
            </div>
          )}
        </dl>
      </section>
      <section className="px-6 flex flex-1">
        <div className="flex items-center flex-1 lg:pr-6">
          <dl className="text-xs flex-1 group relative">
            <div className="flex space-x-2 md:justify-end">
              <dt className="text-gray-200">{t('progress')}</dt>
              <dd className="text-brand-blue">
                {number(progress, { style: 'percent' })}
              </dd>
            </div>
            <div className="rounded-full h-[0.325rem] bg-gray-600 my-2">
              <div
                style={{
                  width: `${Math.max(0, Math.min(progress * 100, 100))}%`,
                }}
                className="rounded-full bg-brand-blue h-[0.325rem] transition-[width]"
              />
            </div>
            <div className="flex space-x-2 md:justify-end">
              <dt className="text-gray-200">
                {t(status === 'LIVE' ? 'ends' : 'ended')}
              </dt>
              <dd className="text-white">
                {status === 'LIVE'
                  ? relativeTime(ends, { now, unit })
                  : dateTime(ends, { dateStyle: 'medium' })}
              </dd>
            </div>
            {status === 'LIVE' && !!strategy?.length && (
              <div className="hidden group-hover:block absolute top-full -mt-2 left-4 rounded-xl bg-gray-400 px-4 py-2 min-w-[50%]">
                <StrategyYieldTable
                  chain={chain}
                  currentYield={currentYield}
                  strategy={strategy}
                  strategyYield={strategyYield}
                />
              </div>
            )}
          </dl>
        </div>
        <aside className="flex space-x-4 items-center lg:border-l border-gray-400 pl-4 md:pl-6">
          {status === 'LIVE' && (
            <div className="text-center flex flex-col items-center">
              <CircularProgress percentage={participation} />
              <p className="text-gray-200 text-xs mt-1">{t('participation')}</p>
            </div>
          )}
          {!!sweepTx ? (
            <a
              href={`${chain.blockExplorers?.default.url}/tx/${sweepTx}`}
              target="_blank"
              rel="nofollow noreferrer"
            >
              <EpochStatus status={status} />
            </a>
          ) : (
            <EpochStatus status={status} />
          )}
        </aside>
      </section>
    </div>
  );
};

export default InfoBar;
