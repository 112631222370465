'use client';
import { useAccount } from 'wagmi';
import Disconnected from './Disconnected';
import Vote from './Vote';
import { useCallback, useMemo, useState } from 'react';
import ConnectButton from '@/presentation/components/modules/ConnectButton';
import useCollection from '@/application/collections/useCollection';
import useSweepPower from '@/application/staking/useSweepPower';
import useUserActiveVotes from '@/application/epochs/useUserActiveVotes';
import useVoteOnSweepWar from '@/application/epochs/useVoteOnSweepWar';
import { absBigInt } from '@/utils/bigint';
import useEpoch from '@/application/epochs/useEpoch';
import useRevokeVotes from '@/application/epochs/useRevokeVotes';
import useVoteLock from '@/application/staking/useVoteLock';
import { Address, formatEther } from 'viem';
import { useChain } from '@/hooks/useChain';
import useMinSweepAmount from '@/application/sweeps/useMinSweep';
import useCurrentEpochYield from '@/application/epochs/useCurrentEpochYield';

const VoteContainer = ({
  collectionId,
  epochId,
}: {
  collectionId?: string;
  epochId: string;
}) => {
  const chain = useChain();
  const [voteDirection, setVoteDirection] = useState<'for' | 'against'>('for');
  const { isConnected, address } = useAccount();
  const { data: sweepPower } = useSweepPower({
    address,
    watch: true,
  });
  const { data: minSweepAmount } = useMinSweepAmount();
  const { data: collection } = useCollection(collectionId);
  const { data: epochYieldData } = useCurrentEpochYield();
  const { data: epoch } = useEpoch(epochId);
  const { data: userActiveVotes } = useUserActiveVotes({
    userAddress: address,
    type: epoch?.type,
    epochId,
  });

  const {
    revokeVotes,
    isConfirmed: isRevokeConfirmed,
    isPending: isRevokePending,
    isConfirming: isRevokeConfirming,
    error: revokeError,
  } = useRevokeVotes();

  const { data: voteLock } = useVoteLock({
    userAddress: address,
  });

  // we only want active votes for this epoch type
  const userVotes = useMemo(
    () => userActiveVotes?.filter((vote) => !vote.revoked),
    [userActiveVotes],
  );

  const {
    vote,
    isConfirmed: isVoteConfirmed,
    isConfirming: isVoteConfirming,
    isPending: isVotePending,
    error: voteError,
  } = useVoteOnSweepWar();

  const [, , currentYield] = epochYieldData || [];

  const lockRemaining =
    voteLock && epoch
      ? BigInt(voteLock?.lockEnd) - BigInt(epoch?.id)
      : undefined;

  const status = useMemo(() => {
    if (!isConnected) return 'disconnected';
    if (collectionId) return 'selected';

    return 'unselected';
  }, [isConnected, collectionId]);

  const sweepSize = epoch?.sweep?.sweepSize;

  // use the minSweepAmount if it's higher than the currentYield
  const sweepAmount = useMemo(() => {
    if (sweepSize != null) {
      return BigInt(sweepSize);
    }
    if (minSweepAmount != null && currentYield != null) {
      return minSweepAmount > currentYield ? minSweepAmount : currentYield;
    }

    if (minSweepAmount != null) {
      return minSweepAmount;
    }

    // still awaiting values
    return null;
  }, [currentYield, minSweepAmount, sweepSize]);

  const handleVote = useCallback(
    (collectionAddress: Address, amount: bigint) =>
      vote([
        collectionAddress,
        voteDirection === 'against' ? 0n - amount : amount,
      ]),
    [voteDirection, vote],
  );

  const handleRevokeVotes = useCallback(() => {
    if (userVotes) {
      revokeVotes(userVotes.map((vote) => vote.collection.id));
    }
  }, [userVotes, revokeVotes]);

  // highest voted first
  const collections = useMemo(() => {
    if (!epoch) {
      return [];
    }

    return epoch.collections
      .sort(
        (a, b) =>
          Number(formatEther(BigInt(b.votesFor))) -
          Number(formatEther(BigInt(b?.votesAgainst || 0))) -
          (Number(formatEther(BigInt(a.votesFor))) -
            Number(formatEther(BigInt(a.votesAgainst || 0)))),
      )
      .filter((a) => a.collection.symbol !== 'WETH');
  }, [epoch]);

  if (status === 'disconnected') {
    return <Disconnected connect={<ConnectButton className="w-full" />} />;
  }

  const remainingSweepPower =
    (sweepPower || 0n) -
    (userVotes?.reduce((acc, cur) => {
      // only count votes that are not revoked
      return acc + absBigInt(BigInt(cur.votesCast));
    }, 0n) || 0n);

  const collectionSweepPower = (() => {
    const c = epoch?.collections.find((c) => c.collection.id === collectionId);
    return BigInt(c?.votesFor || 0) - BigInt(c?.votesAgainst || 0);
  })();

  const totalVotes =
    epoch?.collections?.reduce((acc, cur) => {
      return acc + BigInt(cur.votesFor) + absBigInt(BigInt(cur.votesAgainst));
    }, 0n) || 0n;

  const winningSweepPowerUsed = collections?.reduce((acc, cur, i) => {
    if (i < Number(epoch?.sampleSize)) {
      return acc + BigInt(cur.votesFor) - BigInt(cur?.votesAgainst || 0n);
    }

    return acc;
  }, 0n);

  const minSweepPowerNeededForSweep = epoch
    ? BigInt(collections[Number(epoch.sampleSize) - 1].votesFor) -
      BigInt(collections[Number(epoch.sampleSize) - 1].votesAgainst || 0n)
    : null;

  const minSweepPowerNeededToRetainSweep = epoch
    ? BigInt(collections[Number(epoch.sampleSize)].votesFor) -
      BigInt(collections[Number(epoch.sampleSize)].votesAgainst || 0n)
    : null;

  const isGettingSwept =
    collections.findIndex((x) => x.collection.id === collectionId) <
    Number(epoch?.sampleSize);

  return (
    <Vote
      chain={chain}
      collection={collectionId ? collection : undefined}
      voteDirection={voteDirection}
      setVoteDirection={setVoteDirection}
      status={status}
      isVotePending={isVotePending}
      isVoteConfirming={isVoteConfirming}
      isVoteConfirmed={isVoteConfirmed}
      voteError={voteError}
      sweepPower={sweepPower}
      remainingSweepPower={remainingSweepPower}
      collectionSweepPower={collectionSweepPower}
      onVoteClick={handleVote}
      onRevokeAllClick={handleRevokeVotes}
      revokeError={revokeError}
      isRevokeConfirmed={isRevokeConfirmed}
      isRevokeConfirming={isRevokeConfirming}
      isRevokePending={isRevokePending}
      activeVotes={userVotes}
      totalVotes={totalVotes}
      lockRemaining={lockRemaining}
      sweepAmount={sweepAmount}
      winningSweepPowerUsed={winningSweepPowerUsed}
      minSweepPowerNeededForSweep={minSweepPowerNeededForSweep}
      minSweepPowerNeededToRetainSweep={minSweepPowerNeededToRetainSweep}
      isGettingSwept={isGettingSwept}
    />
  );
};

export default VoteContainer;
