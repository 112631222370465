import useRevokeVotes from '@/application/epochs/useRevokeVotes';
import { ActiveVote } from '@/core/vote';
import CollectionImage from '@/presentation/components/elements/CollectionImage';
import { absBigInt } from '@/utils/bigint';
import { ArrowPathIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { BaseError } from '@wagmi/core';
import { useFormatter } from 'use-intl';
import { Address, WriteContractErrorType, formatEther } from 'viem';

const ActiveVote = ({ id, votesCast, collection }: ActiveVote) => {
  const { number } = useFormatter();

  const { revokeVotes, isConfirmed, isConfirming, isPending, error } =
    useRevokeVotes();

  const revokeVote = (collectionId: Address) => revokeVotes([collectionId]);

  // successfully revoked
  if (isConfirmed) {
    return null;
  }

  return (
    <>
      <div
        key={id}
        className={`bg-black rounded-lg flex items-center p-2 ${
          isConfirming || isPending ? 'animate animate-pulse' : ''
        }`}
      >
        <CollectionImage
          collection={collection.id}
          diameter={40}
          className="w-10 h-10"
        />
        <div className="flex-1 text-center overflow-hidden px-1">
          <div className="font-display text-xxs truncate">
            {number(Number(formatEther(absBigInt(BigInt(votesCast)))), {
              maximumFractionDigits: 0,
            })}
          </div>
          <div className="text-xs text-white/50">{'SP Used'}</div>
        </div>
        <div className="flex-1 text-center px-1">
          <div className="font-display text-xxs">
            {BigInt(votesCast) > 0 ? (
              <span className="text-brand-blue">{'Sweep'}</span>
            ) : (
              <span className="text-brand-red">{'Sell'}</span>
            )}
          </div>
          <div className="text-xs text-white/50">{'Vote'}</div>
        </div>
        <button
          type="button"
          className="w-10 h-10 hover:text-white text-white/50 flex items-center justify-center"
          onClick={() => revokeVote(collection.id)}
          disabled={isPending || isConfirming}
        >
          {isConfirming ? (
            <ArrowPathIcon className="animate-spin h-6 w-6" />
          ) : (
            <XMarkIcon className="w-6 h-6" />
          )}
        </button>
      </div>
      {error &&
        !(error as BaseError)?.details?.includes(
          'User rejected the request',
        ) && (
          <div className="bg-red-500 text-white p-4 rounded-md text-xs">
            <h4 className="font-bold">{error?.name}</h4>
            <pre className="text-xs overflow-x-scroll">{error?.message}</pre>
          </div>
        )}
    </>
  );
};

export default ActiveVote;
