import { useSettings } from '@/context/settings';
import Price from './Price';
import { NumberFormatOptions } from 'use-intl';
import useEthereumPrice from '@/application/token/useEthereumPrice';
import { useChain } from '@/hooks/useChain';
import { formatEther, formatUnits } from 'viem';

const PriceContainer = ({
  value,
  symbolClassName,
  ...options
}: {
  value?: bigint;
  symbolClassName?: string;
} & NumberFormatOptions) => {
  const chain = useChain();
  const [{ currency }] = useSettings();
  const { data: ethPrice } = useEthereumPrice();

  if (value == null) return '–';

  if (currency === 'USD') {
    if (!ethPrice) return null;
    const price = Number(formatUnits(ethPrice, 6)) * Number(formatEther(value));
    return (
      <Price
        value={price}
        chain={chain}
        currency={currency}
        options={options}
      />
    );
  }

  return (
    <Price
      symbolClassName={symbolClassName}
      value={Number(formatEther(value))}
      chain={chain}
      currency={currency}
      options={options}
    />
  );
};

export default PriceContainer;
