import { Epoch, EpochCollection, Epochs } from '@/core/epoch';
import { useTranslations } from 'use-intl';
import { Chain } from 'viem';
import CollectionTableSweepRow from './TableSweepRow';

const TableSweep = ({
  collections,
  previousCollections,
  collectionId,
  onCollectionClick,
  sampleSize,
  totalSweepPowerUsed,
  winningSweepPowerUsed,
  sweepAmount,
  chain,
  viewOnly,
}: {
  chain: Chain;
  collectionId: any;
  collections?: EpochCollection[];
  previousCollections?: Epochs[0]['newCollections'] | Epochs[0]['collections'];
  onCollectionClick: (c: any) => void;
  sampleSize?: Epoch['sampleSize'];
  totalSweepPowerUsed?: bigint;
  winningSweepPowerUsed?: bigint;
  sweepAmount: bigint | null;
  viewOnly: boolean;
}) => {
  const t = useTranslations('Epoch.Table');

  return (
    <div className="space-y-4">
      <div className="rounded-xl bg-gray-800 py-2">
        <div className="overflow-x-scroll hide-scroll">
          <table className="w-full">
            <thead className="text-left text-xs text-gray-200 whitespace-nowrap">
              <tr>
                <th className="px-4 py-2 font-medium">{t('collection')}</th>
                <th className="px-4 py-2 font-medium">{t(`votes.votes`)}</th>
                <th className="px-4 py-2 font-medium text-right">
                  {t('sweepPower')}
                </th>
                <th className="px-4 py-2 font-medium text-right">
                  {t('sweepAmount')}
                </th>
              </tr>
            </thead>
            <tbody className="text-sm">
              {collections?.map((c, i) => {
                return (
                  <CollectionTableSweepRow
                    {...(c as EpochCollection)}
                    viewOnly={viewOnly}
                    onCollectionClick={onCollectionClick}
                    winningSweepPowerUsed={winningSweepPowerUsed}
                    sweepAmount={sweepAmount}
                    chain={chain}
                    collectionId={collectionId}
                    previousCollections={previousCollections}
                    sampleSize={sampleSize}
                    totalSweepPowerUsed={totalSweepPowerUsed}
                    index={i}
                    key={c.id}
                  />
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className="rounded-xl bg-gray-800 p-4 text-xs">
        <h4 className="text-sm text-white/75 mb-2">{'Sweep War'}</h4>
        <dl className="space-y-2 text-white/75">
          <div className="flex items-center">
            <dt className="mr-2">
              <div className="w-4 h-4 rounded-sm bg-brand-blue/50" />
            </dt>
            <dd>
              {
                'Winning collections will be swept in a Sweep War or added to the Treasury and swept in a New Collection War.'
              }
            </dd>
          </div>
          <div className="flex items-center">
            <dt className="mr-2">
              <div className="w-4 h-4 rounded-sm bg-brand-red/50" />
            </dt>
            <dd>
              {
                'Losing collections that have a negative vote share of >10% will have their assets sold proportionally to the vote share.'
              }
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

export default TableSweep;
