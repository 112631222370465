import { Address } from 'viem';
import { mainnet, sepolia } from 'wagmi/chains';

export const Images = {
  [sepolia.id]: <{ [key: Address]: string }>{
    '0x3d7e741b5e806303adbe0706c827d3acf0696516':
      '/images/collections/coolcats.avif',
    '0xa807e2a221c6daafe1b4a3ed2da5e8a53fdaf6be':
      '/images/collections/pudgypenguins.avif',
    '0xea9af8dbdde2a8d3515c3b4e446ecd41afedb1c6':
      '/images/collections/milady.avif',
    '0xfff9976782d46cc05630d1f6ebab18b2324d6b14':
      '/images/collections/weth.png',
  },
  [mainnet.id]: <{ [key: Address]: string }>{
    // Launch
    '0x3d7e741b5e806303adbe0706c827d3acf0696516':
      '/images/collections/coolcats.avif',
    '0xabea7663c472648d674bd3403d94c858dfeef728':
      '/images/collections/pudgypenguins.avif',
    '0x5af0d9827e0c53e4799bb226655a1de152a425a5':
      '/images/collections/milady.avif',
    '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2':
      '/images/collections/weth.png',
    '0x059edd72cd353df5106d2b9cc5ab83a52287ac3a':
      '/images/collections/chromiesquiggles.avif',
    '0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb':
      '/images/collections/cryptopunks.avif',
    '0xe21ebcd28d37a67757b9bc7b290f4c4928a430b1':
      '/images/collections/saudis.webp',
    '0x09f66a094a0070ebddefa192a33fa5d75b59d46b':
      '/images/collections/yayo.avif',
    '0x521f9c7505005cfa19a8e5786a9c3c9c9f5e6f42':
      '/images/collections/forgottenruneswizardscult.png',
    '0x31385d3520bced94f77aae104b406994d8f2168c':
      '/images/collections/bgans.webp',
    '0xd3d9ddd0cf0a5f0bfb8f7fceae075df687eaebab':
      '/images/collections/remilios.webp',

    // New Collection War 3
    '0x524cab2ec69124574082676e6f654a18df49a048':
      '/images/collections/lilpudgys.avif',
    '0x364c828ee171616a39897688a831c2499ad972ec':
      '/images/collections/sappyseals.avif',
    '0x1a92f7381b9f03921564a437210bb9396471050c':
      '/images/collections/coolcats.avif',
    '0xc3f733ca98e0dad0386979eb96fb1722a1a05e69':
      '/images/collections/mooncats.webp',
    '0x32973908faee0bf825a343000fe412ebe56f802a':
      '/images/collections/pixelmons.avif',
    '0xb6a37b5d14d502c3ab0ae6f3a0e058bc9517786e':
      '/images/collections/azukielementals.avif',
    '0xacf63e56fd08970b43401492a02f6f38b6635c91':
      '/images/collections/kenpaipandas.webp',
    '0x1d20a51f088492a0f1c57f047a9e30c9ab5c07ea':
      '/images/collections/wassies.avif',
    '0x8821bee2ba0df28761afff119d66390d594cd280':
      '/images/collections/degods.avif',
    '0xef1a89cbfabe59397ffda11fc5df293e9bc5db90':
      '/images/collections/basedghouls.avif',
    '0xf729f878f95548bc7f14b127c96089cf121505f8':
      '/images/collections/anata.avif',
    '0x7bd29408f11d2bfc23c34f18275bbf23bb716bc7':
      '/images/collections/meebits.avif',
    '0x18a62e93ff3ab180e0c7abd4812595bf2be3405f':
      '/images/collections/xcopy.webp',
    '0x7dbc433c92266ab268ae1040837a212b77fec393':
      '/images/collections/thebuns.avif',
  },
} as const;
