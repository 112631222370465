import type { ActiveVote as ActiveVoteType } from '@/core/vote';
import ActiveVote from './ActiveVote';

const ActiveVotes = ({ activeVotes }: { activeVotes: ActiveVoteType[] }) => {
  return (
    <ul className="space-y-2">
      {activeVotes?.map((vote) => <ActiveVote {...vote} key={vote.id} />)}
    </ul>
  );
};

export default ActiveVotes;
