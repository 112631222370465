import type { QueryStatus } from '@tanstack/react-query';

const precedence: QueryStatus[] = ['success', 'error', 'pending'];

const getWeakestStatus = (...statuses: QueryStatus[]) => {
  return statuses.reduce((acc, status) => {
    return precedence.indexOf(status) > precedence.indexOf(acc) ? status : acc;
  }, 'success');
};

export default getWeakestStatus;
