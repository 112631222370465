import useEpochVotes from '@/application/epochs/useEpochVotes';
import Voters from './Voters';
import { useChain } from '@/hooks/useChain';
import { formatEther } from 'viem';
import { Epoch } from '@/core/epoch';

const VotersContainer = ({
  epochId,
  type,
}: {
  epochId?: string;
  type: Epoch['type'];
}) => {
  const chain = useChain();
  const { data: votes } = useEpochVotes({ epochId, type });

  return (
    <Voters
      votes={votes
        ?.map((v) => v.activeVotes)
        .flat()
        .sort(
          (a, b) =>
            Math.abs(Number(formatEther(BigInt(b.votesCast)))) -
            Math.abs(Number(formatEther(BigInt(a.votesCast)))),
        )}
      chain={chain}
    />
  );
};

export default VotersContainer;
