'use client';
import Epoch from './Epoch';
import { useEffect, useMemo, useState } from 'react';
import useEpochs from '@/application/epochs/useEpochs';
import useEpoch from '@/application/epochs/useEpoch';
import EpochLoading from './EpochLoading';
import getWeakestStatus from '@/utils/getWeakestStatus';
import EpochEmpty from './EpochEmpty';
import useChainId from '@/hooks/useChainId';

const EpochContainer = ({ id }: { id?: string | null }) => {
  const chainId = useChainId();
  const [epochId, setEpochId] = useState<string>();
  const [collectionId, setCollectionId] = useState<string>();
  const { data: epochs, status: epochsStatus } = useEpochs();
  const { data: epoch, status: epochStatus } = useEpoch(epochId);

  const sweepStatus = useMemo(() => {
    if (epoch?.endTime == null) {
      return 'LIVE';
    }

    return epoch.sweep?.status;
  }, [epoch]);

  // if epoch id is provided, use that
  // otherwise use the latest epoch (e.g. homepage)
  useEffect(() => {
    if (!epochId) {
      if (id) {
        setEpochId(id);
      } else {
        setEpochId(epochs?.[0]?.id);
      }
    }
    // clear out selected collections when epoch changes
    setCollectionId(undefined);
  }, [id, epochs, epochId]);

  const status = getWeakestStatus(epochsStatus, epochStatus);

  const setSelectedEpoch = (epoch: string) => {
    setEpochId(epoch);
    document.title = `FLOOR _ WAR _ ${epoch}`;
    window.history.replaceState({}, '', `/wars/${epoch}`);
  };

  if (status === 'pending' || !epochs) {
    return <EpochLoading />;
  }

  if (!epoch) {
    return (
      <EpochEmpty
        epochs={epochs}
        selected={epochId}
        setSelected={setSelectedEpoch}
      />
    );
  }

  return (
    <Epoch
      chainId={chainId}
      epochs={epochs}
      selected={epochId}
      setSelected={setSelectedEpoch}
      collection={collectionId}
      setCollection={setCollectionId}
      sweepStatus={sweepStatus}
      type={epoch.type}
    />
  );
};

export default EpochContainer;
