import { Epochs } from '@/core/epoch';
import { getChainConstant } from '@/domain/chain';
import { Images } from '@/domain/images';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import Image from 'next/image';
import { useCallback, useRef, useState } from 'react';
import { useFormatter, useTranslations } from 'use-intl';
import { useOnClickOutside } from 'usehooks-ts';
import { Chain, formatEther } from 'viem';

export interface SelectEpochProps {
  chain: Chain;
  onChange: (war: any) => void;
  active: any;
  epochs: Epochs;
}

function getSweepWinnerIcon(chain: Chain, w: Epochs[0]) {
  return (
    getChainConstant(
      Images,
      chain.id,
      w.collections
        .filter((x) => x.collection.symbol !== 'WETH')
        .toSorted((a, b) => {
          const bVotes =
            Number(formatEther(BigInt(b.votesFor))) -
            Number(formatEther(BigInt(b?.votesAgainst || 0)));
          const aVotes =
            Number(formatEther(BigInt(a.votesFor))) -
            Number(formatEther(BigInt(a?.votesAgainst || 0)));

          return bVotes - aVotes;
        })[0]?.collection?.id,
    ) || '/images/floorwar.png'
  );
}

const SelectEpoch = ({ onChange, active, epochs, chain }: SelectEpochProps) => {
  const t = useTranslations('Epoch.SelectEpoch');
  const { number } = useFormatter();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const ref = useRef<HTMLDivElement>(null);

  const [showEpochs, setShowEpochs] = useState(false);

  const activeEpoch = epochs?.find((w) => w?.id === active);

  const closeOnClickOff = useCallback(
    () => showEpochs && setShowEpochs(false),
    [showEpochs],
  );
  useOnClickOutside(ref, closeOnClickOff);

  return (
    <div
      className={`relative flex bg-black ${
        showEpochs ? 'rounded-t-xl' : 'rounded-xl'
      } p-2 md:p-4`}
      ref={ref}
    >
      <button
        type="button"
        className={`flex items-center cursor-pointer flex-1 whitespace-nowrap`}
        onClick={() => setShowEpochs((x) => !x)}
        ref={buttonRef}
      >
        {activeEpoch?.type === 'NEW_COLLECTION_WAR' ? (
          <Image
            src="/images/plus.png"
            width={51}
            height={51}
            alt="New Collection"
          />
        ) : (
          <Image
            src={
              activeEpoch?.endTime != null
                ? getSweepWinnerIcon(chain, activeEpoch)
                : '/images/floorwar.png'
            }
            width={51}
            height={51}
            alt="Sweep War"
            className="rounded-full"
          />
        )}
        <span className="flex flex-col items-start ml-4 text-left truncate">
          <span className="text-lg">{t('option', { id: active })}</span>
          <span className="text-xs text-gray-200 min-w-[16ch]">
            {t(`type.${activeEpoch?.type || 'SWEEP_WAR'}`)}
          </span>
        </span>
        <span className="ml-auto px-4 text-sm text-brand-blue min-w-[10ch]">
          {activeEpoch?.sweep?.sweepSize &&
            `${number(
              Number(formatEther(BigInt(activeEpoch.sweep?.sweepSize || 0))),
            )} ${chain.nativeCurrency.symbol}`}
        </span>
        <ChevronDownIcon
          className={`w-4 h-4 flex-none transform ${
            showEpochs ? 'rotate-180' : ''
          }`}
        />
      </button>
      {showEpochs && (
        <div className="absolute top-full left-0 right-0 rounded-b-xl bg-black px-2 z-10 pb-2">
          <div className="styled-scroll max-h-44 overflow-y-scroll pr-1 space-y-2">
            {epochs.map((w) => (
              <button
                type="button"
                key={w.id}
                onClick={() => {
                  onChange(w.id);
                  setShowEpochs(false);
                }}
                className={`p-2 md:py-4 ${
                  w.id === activeEpoch?.id ? 'bg-gray-800 bg-opacity-80' : ''
                } hover:bg-gray-600 rounded-xl cursor-pointer text-left flex items-center text-sm w-full`}
              >
                {w?.type === 'NEW_COLLECTION_WAR' ? (
                  <Image
                    src="/images/plus.png"
                    width={51}
                    height={51}
                    alt="New Collection"
                  />
                ) : (
                  <Image
                    src={
                      w.sweep?.status === 'SWEPT'
                        ? getSweepWinnerIcon(chain, w)
                        : '/images/floorwar.png'
                    }
                    width={51}
                    height={51}
                    alt="Sweep War"
                    className="rounded-full"
                  />
                )}
                <span className="flex flex-col items-start ml-4 text-left truncate">
                  <span className="text-lg truncate">
                    {t('option', { id: w?.id })}
                  </span>
                  <span className="text-xs text-gray-200">
                    {t(`type.${w?.type || 'SWEEP_WAR'}`)}
                  </span>
                </span>
                <span className="ml-auto px-4 text-sm text-brand-blue min-w-[8ch] text-right">
                  {w?.sweep?.sweepSize &&
                    `${number(
                      Number(formatEther(BigInt(w.sweep?.sweepSize || 0))),
                    )} ${chain.nativeCurrency.symbol}`}
                </span>
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectEpoch;
