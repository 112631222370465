import { ActiveVote } from '@/core/vote';
import CollectionImage from '@/presentation/components/elements/CollectionImage';
import { absBigInt } from '@/utils/bigint';
import { useFormatter } from 'use-intl';
import { formatEther } from 'viem';

const ActiveVote = ({ id, votesCast, collection }: ActiveVote) => {
  const { number } = useFormatter();

  return (
    <div
      key={id}
      className={`bg-black rounded-lg flex items-center p-2 ${
        status === 'loading' ? 'animate animate-pulse' : ''
      }`}
    >
      <CollectionImage
        collection={collection.id}
        diameter={40}
        className="w-10 h-10"
      />
      <div className="flex-1 text-center overflow-hidden px-1">
        <div className="font-display text-xxs truncate">
          {number(Number(formatEther(absBigInt(BigInt(votesCast)))), {
            maximumFractionDigits: 0,
          })}
        </div>
        <div className="text-xs text-white/50">{'SP Used'}</div>
      </div>
      <div className="flex-1 text-center px-1">
        <div className="font-display text-xxs">
          {BigInt(votesCast) > 0 ? (
            <span className="text-brand-blue">{'For'}</span>
          ) : (
            <span className="text-brand-red">{'Sell'}</span>
          )}
        </div>
        <div className="text-xs text-white/50">{'Vote'}</div>
      </div>
      {/* <button
        type="button"
        className="w-10 h-10 hover:text-white text-white/50 flex items-center justify-center"
        onClick={() => revokeVote(collection.id)}
        disabled={status === 'loading'}
      >
        {status === 'loading' ? (
          <ArrowPathIcon className="animate-spin h-6 w-6" />
        ) : (
          <XMarkIcon className="w-6 h-6" />
        )}
      </button> */}
    </div>
  );
};

export default ActiveVote;
