import React from 'react';

const CircularProgress = ({
  percentage,
  size = 60, // Default size is 120
}: {
  percentage?: number;
  size?: number;
}) => {
  const isPending = percentage === undefined;
  const strokeWidth = size / 12; // Fixed stroke width
  const radius = size / 2 - strokeWidth / 2; // Adjust radius based on size, considering stroke width
  const circumference = 2 * Math.PI * radius; // Circumference of the circle
  const progress = circumference * (1 - (percentage || 0)); // Calculate the stroke-dashoffset

  return (
    <div
      className={`relative flex justify-center items-center ${
        isPending ? 'animate-pulse' : ''
      }`}
      style={{ width: size, height: size }}
    >
      <svg width={size} height={size}>
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={strokeWidth}
          fill="transparent"
          className="text-gray-400 stroke-current"
        />
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={strokeWidth}
          fill="transparent"
          strokeDasharray={circumference}
          strokeDashoffset={progress}
          transform={`rotate(-90 ${size / 2} ${size / 2})`}
          className="text-brand-green stroke-current"
        />
      </svg>
      <div
        className="absolute text-xs font-semibold font-display text-brand-green"
        style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
      >
        {!isPending && `${Math.min(100, Math.round(percentage * 100))}%`}
      </div>
    </div>
  );
};

export default CircularProgress;
