'use client';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import InfoBar from './InfoBar';
import SelectEpoch from '../SelectEpoch';
import useEpoch from '@/application/epochs/useEpoch';
import { useInterval } from 'usehooks-ts';
import useMinSweepAmount from '@/application/sweeps/useMinSweep';
import { Epochs } from '@/core/epoch';
import useCurrentEpochYield from '@/application/epochs/useCurrentEpochYield';
import { useChain } from '@/hooks/useChain';
import { formatEther } from 'viem';
import useTotalSweepPowerUsed from '@/application/staking/useTotalSweepPowerUsed';
import useTotalSweepPower from '@/application/staking/useTotalSweepPower';

export interface InfoBarContainerProps {
  selected: string;
  setSelected: Dispatch<SetStateAction<string>>;
  epochs: Epochs;
}

const InfoBarContainer = ({
  selected,
  setSelected,
  epochs,
}: InfoBarContainerProps) => {
  const [now, setNow] = useState(Date.now());
  useInterval(() => setNow(Date.now()), 10000);

  const chain = useChain();
  const { data: epoch } = useEpoch(selected);
  const { data: minSweepAmount } = useMinSweepAmount();
  const { data: epochYieldData } = useCurrentEpochYield();

  const { data: totalSweepPowerUsed } = useTotalSweepPowerUsed({
    epochId: selected,
    type: epoch?.type,
  });

  const { data: totalSweepPower } = useTotalSweepPower();

  const [strategy, strategyYield, currentEpochYield] = epochYieldData || [];

  const status = useMemo<'PENDING' | 'SWEPT' | 'LIVE'>(() => {
    // we have a status within the epoch sweep
    if (epoch?.sweep) {
      return epoch.sweep.status;
    }

    //if no `epoch.sweep` does this just mean it's live?
    return 'LIVE';
  }, [epoch]);

  const currentYield = useMemo(() => {
    // if the epoch is live or pending, we can use the current epoch yield
    if (
      (status === 'LIVE' || status === 'PENDING') &&
      currentEpochYield != null
    ) {
      return currentEpochYield;
    }

    // if the epoch is swept, we can just use the sweep size
    if (status === 'SWEPT' && epoch) {
      return BigInt(epoch.sweep?.sweepSize || 0);
    }

    // if we don't have an epoch, or we somehow fell through
    return 0n;
  }, [currentEpochYield, epoch, status]);

  if (!epoch) {
    return (
      <div className="rounded-xl bg-gray-800 py-2 md:py-4 space-y-4 md:space-y-0 md:flex">
        <section className="px-2 md:px-4 w-1/2 flex-none flex items-center">
          <div
            className={`relative flex bg-gray-600 h-20 p-2 md:p-4 rounded-lg w-1/2`}
          />
          <div className="bg-gray-600 h-10 flex-1 ml-8 rounded-md" />
        </section>
        <section className="px-2 md:px-4 md:flex flex-1 md:pl-0 items-center space-x-4">
          <div className="bg-gray-600 h-1 flex-1 ml-8 rounded-md" />
          <div className="w-24 h-10 rounded-full bg-gray-600" />
        </section>
      </div>
    );
  }

  const endTime = epoch.endTime
    ? Number(epoch.endTime) * 1000
    : (Number(epoch.startTime) + 24 * 60 * 60 * (chain.testnet ? 2 : 14)) *
      1000;

  const progress = Math.min(
    Math.max(
      (now / 1000 - Number(epoch.startTime)) /
        (endTime / 1000 - Number(epoch.startTime)),
      0,
    ),
    1,
  );

  const participation =
    totalSweepPowerUsed && totalSweepPower
      ? Number(formatEther(totalSweepPowerUsed)) /
        Number(formatEther(totalSweepPower))
      : undefined;

  const props = {
    progress,
    ends: endTime,
    now,
    status,
    minSweep: minSweepAmount || 0n,
    sweepSize: BigInt(epoch.sweep?.sweepSize || 0),
    sweepTx: epoch.sweep?.transaction,
    epochs,
    currentYield,
    strategy,
    strategyYield,
    participation,
  };

  return (
    <InfoBar
      chain={chain}
      {...props}
      selectEpoch={
        <SelectEpoch
          active={selected}
          onChange={setSelected}
          epochs={epochs}
          chain={chain}
        />
      }
    />
  );
};

export default InfoBarContainer;
