import useStrategies from '@/application/strategies/useStrategies';
import { Addresses } from '@/domain/addresses';
import { getChainConstant } from '@/domain/chain';
import { useMemo } from 'react';
import { useFormatter, useTranslations } from 'use-intl';
import { Address, Chain, formatEther } from 'viem';

const CollectionRow = ({
  id,
  amount,
  name,
  total,
  chain,
}: {
  id: Address;
  amount: bigint;
  name: string;
  total: bigint;
  chain: Chain;
}) => {
  const { number } = useFormatter();

  return (
    <tr key={id}>
      <td className="py-1 pr-6">{name}</td>
      <td className="py-1 px-6">
        {number(Number(formatEther(amount)), {
          minimumFractionDigits: 3,
        })}
        {` ${chain.nativeCurrency.symbol}`}
      </td>
      <td className="py-1 pl-6">
        <div className="rounded-full h-[0.325rem] bg-gray-600">
          <div
            style={{
              width:
                total > 0
                  ? `${Math.min(
                      (Number(formatEther(amount)) /
                        Number(formatEther(total))) *
                        100,
                      100,
                    )}%`
                  : '0%',
            }}
            className="rounded-full bg-brand-blue h-[0.325rem] transition-[width]"
          />
        </div>
      </td>
    </tr>
  );
};

interface StrategyYieldTableProps {
  strategy: Address[];
  strategyYield: bigint[];
  currentYield: bigint;
  chain: Chain;
}

const StrategyYieldTable = ({
  strategy,
  strategyYield,
  currentYield,
  chain,
}: StrategyYieldTableProps) => {
  const t = useTranslations('Epoch.InfoBar');
  const { data: strategies } = useStrategies();

  const collectionYields = useMemo(() => {
    if (strategy && strategyYield && strategies) {
      return Object.values(
        strategy?.reduce(
          (acc, strategyId, index) => {
            const s = strategies?.find(
              (s) => s.id.toLowerCase() === strategyId.toLowerCase(),
            );
            const collectionId = s?.collection.id;
            const yieldValue = BigInt(strategyYield[index]);

            if (!collectionId) {
              return acc;
            }

            // Skip WETH
            const isWeth =
              collectionId.toLowerCase() ===
              getChainConstant(Addresses, chain.id, 'WETH');

            // add yield to collection
            if (!acc[collectionId]) {
              acc[collectionId] = {
                id: collectionId,
                amount: yieldValue,
                name: isWeth ? 'Other' : s?.collection.name || s?.name,
              };
            } else {
              acc[collectionId].amount += yieldValue;
            }

            return acc;
          },
          {} as {
            [key: string]: { id: Address; amount: bigint; name: string };
          },
        ) || {},
      ).sort(
        (a, b) => Number(formatEther(b.amount)) - Number(formatEther(a.amount)),
      );
    }

    return [];
  }, [strategy, strategyYield, strategies, chain.id]);

  return (
    <table className="text-xxs w-full">
      <thead className="text-gray-200 text-left">
        <tr>
          <th className="py-1 pr-6 font-medium">{t('table.collection')}</th>
          <th className="py-1 px-6 font-medium">{t('table.yield')}</th>
          <th className="py-1 pl-6 font-medium">{t('table.share')}</th>
        </tr>
      </thead>
      <tbody>
        {collectionYields.map(({ id, name, amount }, i) => (
          <CollectionRow
            key={id}
            id={id}
            name={name}
            amount={amount}
            total={currentYield}
            chain={chain}
          />
        ))}
      </tbody>
    </table>
  );
};

export default StrategyYieldTable;
