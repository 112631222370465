import { Addresses } from '@/domain/addresses';
import { getChainConstant } from '@/domain/chain';
import useChainId from '@/hooks/useChainId';
import NewCollectionWarsAbi from '@/infrastructure/abis/NewCollectionWarsAbi';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { Address } from 'viem';
import { useWaitForTransactionReceipt, useWriteContract } from 'wagmi';

const useVoteOnCollectionWar = () => {
  const client = useQueryClient();
  const chainId = useChainId();
  const {
    writeContract,
    isPending,
    error,
    data: hash,
    reset,
  } = useWriteContract();

  const vote = (collection: Address) =>
    writeContract({
      abi: NewCollectionWarsAbi,
      address: getChainConstant(Addresses, chainId, 'NewCollectionWars'),
      functionName: 'vote',
      args: [collection],
    });

  const {
    data,
    isSuccess: isConfirmed,
    isLoading: isConfirming,
  } = useWaitForTransactionReceipt({
    hash: hash,
    query: {
      // prevent previous tx state from being used
      placeholderData: undefined,
    },
  });

  useEffect(() => {
    if (isConfirmed) {
      setTimeout(() => {
        client.refetchQueries({
          queryKey: ['userActiveVotes'],
          exact: false,
        });
        client.refetchQueries({ queryKey: ['epochs'], exact: false });
      }, 1500);
      setTimeout(() => {
        console.log('refetching epoch');
        client.refetchQueries({ queryKey: ['epoch'], exact: false });
        reset();
      }, 3000);
    }
  }, [isConfirmed, client, reset]);

  return {
    vote,
    isPending,
    isConfirming,
    isConfirmed,
    error,
    receipt: data,
  };
};

export default useVoteOnCollectionWar;
